import { useUserPermissionsContext } from '../../../../context/user-permissions.context';
import diagramActions from '../../../../redux/diagram/diagramActions';
import { useAppDispatch } from '../../../../redux/hooks';
import Controls, { ContainerType } from '../controls.component';
import LoadingPreview from '../loading-preview';

interface ConsequencesProps {
  isLoading: boolean;
}

const Consequences = ({ isLoading }: ConsequencesProps) => {
  const dispatch = useAppDispatch();
  const userPermissions = useUserPermissionsContext();
  const canCreateConsequences = userPermissions.createRecord.consequences;

  const addConsequenceInput = () => {
    dispatch(diagramActions.doAddConsequenceInput());
  };

  return (
    <Controls
      id="consequences-container"
      type={ContainerType.CONSEQUENCES}
      onAdd={isLoading || !canCreateConsequences ? undefined : addConsequenceInput}
    >
      {isLoading ? (
        <LoadingPreview message="Consequences will be displayed here" />
      ) : canCreateConsequences ? (
        'Add consequences in this box'
      ) : (
        'Consequences will be displayed here'
      )}
    </Controls>
  );
};

export default Consequences;
