import { Button, ToggleButton } from '@myosh/odin-components';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useGetBowtieConfigurationQuery, useSetBowtieConfigurationMutation } from '../api/enhanced/enhanced-v4-api';
import MyoshBowtieLogo from '../assets/svg/myosh-bowtie-logo.svg';
import LoadingPreview from '../components/diagram/shared/loading-preview';

interface ConfigurationOptionType {
  label: string;
  name: string;
  value: boolean | undefined;
  type: string;
}

const configurationOptions: ConfigurationOptionType[] = [
  {
    label: 'AI Enabled',
    name: 'aiEnabled',
    value: false,
    type: 'toggle',
  },
];

function Configuration() {
  const navigate = useNavigate();
  const location = useLocation();

  const { data: bowtieConfig, isFetching, isError } = useGetBowtieConfigurationQuery();
  const [setBowtieConfiguration] = useSetBowtieConfigurationMutation();
  const [options, setOptions] = useState<ConfigurationOptionType[]>(configurationOptions);

  const handleClickCancel = () => {
    navigate(location.state?.from || '/', { replace: true });
  };

  const handleClickSave = async () => {
    const payload = {
      aiEnabled: !!options[0].value,
    };

    try {
      const res = await setBowtieConfiguration({ bowtieConfigDto: payload }).unwrap();
      if (res.result === true) {
        navigate(location.state?.from || '/', { replace: true });
        toast.success('Configuration saved successfully');
      } else {
        toast.error('Error saving bowtie configuration, please try again.');
      }
    } catch (error) {
      toast.error('Error saving bowtie configuration, please try again.');
    }
  };

  const handleToggleChange = (name: string, value: boolean) => {
    const updatedOptions = options.map((item) => {
      if (item.name === name) {
        return { ...item, value: value };
      }
      return item;
    });
    setOptions(updatedOptions);
  };

  useEffect(() => {
    if (!bowtieConfig) return;
    if (isError) {
      toast.error('Error fetching bowtie configuration, refresh the page to retry.');
      return;
    }
    const result: { [key: string]: boolean } = bowtieConfig || {};
    const updatedOptions = options.map((item) => {
      return { ...item, value: result[item.name] ?? item.value };
    });
    setOptions(updatedOptions);
  }, [bowtieConfig, isError]);

  return (
    <div className="bt-flex bt-h-full bt-w-full bt-flex-col bt-items-center bt-gap-8 bt-px-16 bt-py-2">
      <div className="bt-flex bt-h-12 bt-w-full bt-gap-8 bt-align-bottom">
        <img className="bt-w-[90px]" src={MyoshBowtieLogo} alt="myosh-bowtie-logo" />
        <p className="bt-text-4xl bt-font-bold">Configuration</p>
      </div>

      <div className="bt-flex bt-h-[50%] bt-w-[25%] bt-flex-col bt-gap-4 bt-pb-8">
        {isFetching ? (
          <LoadingPreview message="" />
        ) : (
          <>
            {options.map((item) => {
              return (
                <div key={item.name} className="bt-flex bt-w-full bt-flex-row bt-justify-between">
                  <p className="bt-text-lg bt-font-semibold">{item.label}</p>
                  <ToggleButton onChange={(value) => handleToggleChange(item.name, value)} checked={!!item.value} />
                </div>
              );
            })}
            <div className="bt-ml-auto bt-mt-auto bt-flex bt-flex-row bt-gap-4">
              <Button variant="regular" type="text" onClick={handleClickCancel} style={{ maxWidth: '200px' }}>
                Cancel
              </Button>
              <Button variant="alternative" type="primary" onClick={handleClickSave} style={{ maxWidth: '200px' }}>
                Save
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Configuration;
