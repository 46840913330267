import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  BowtieRequestWithId,
  BowtieSuggestPostApiArg,
  ControlType,
  useBowtieSuggestPostMutation,
} from '../api/generated/ai-api';
import diagramSelectors from '../redux/diagram/diagramSelectors';
import { useAppSelector } from '../redux/hooks';
import {
  BowtieCauseRecord,
  BowtieConsequenceRecord,
  BowtieStateCauseData,
  BowtieStateConsequenceData,
  BowtieStateData,
} from '../services/bowtie-data-types';

/**
 * Custom hook to handle AI analysis for bowtie data.
 *
 * @param {Function} onAIAnalysisReady - Callback function to be called when AI analysis is ready. Receives the analysis text as an argument.
 *
 * @returns {Object} - An object containing:
 *   - `runAIAnalysis` (Function): Function to trigger the AI analysis.
 *   - `isLoading` (boolean): Indicates whether the AI analysis is currently loading.
 *
 * @example
 * const { runAIAnalysis, isLoading } = useAIAnalysis((text) => {
 *   console.log('AI Analysis Result:', text);
 * });
 *
 * // Trigger AI analysis
 * runAIAnalysis();
 */
export const useAIAnalysis = (onAIAnalysisReady: (text: string) => void, flowBowtieDto?: BowtieRequestWithId) => {
  const bowtieData = useAppSelector(diagramSelectors.selectBowtieData);

  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const [getSuggestion, getSuggestionResult] = useBowtieSuggestPostMutation();

  const handleBowtieAIAnalysisClick = useCallback(async () => {
    let payload;
    if (flowBowtieDto) {
      // use provided bowtie data when a flow diagram is being analyzed
      payload = generateFlowSuggestPayload(flowBowtieDto);
    } else {
      payload = generateSuggestPayload(bowtieData, searchParams.get('term') as string);
    }

    toast.promise(
      getSuggestion(payload)
        .unwrap()
        .then((data) => onAIAnalysisReady(data?.suggestion_text)),
      {
        pending: 'Running AI Analysis...',
        success: { render: 'AI Analysis Complete', autoClose: 1200, type: 'info' },
        error: 'AI Analysis Failed',
      }
    );
  }, [bowtieData, flowBowtieDto]);

  return {
    runAIAnalysis: handleBowtieAIAnalysisClick,
    isLoading: getSuggestionResult?.isLoading,
  };
};

// util
enum ControlCategory {
  SOFT = 'Soft',
  HARD = 'Hard',
}

const generateSuggestPayload = (bowtieData: BowtieStateData, term?: string): BowtieSuggestPostApiArg => {
  const causes = bowtieData?.causes?.map((cause: BowtieStateCauseData) => {
    return {
      id: String(cause.id),
      name: cause.value,
      controls: cause.preventativeControls.map((preventativeControl: BowtieCauseRecord) => {
        return {
          id: String(preventativeControl.id),
          isCritical: preventativeControl.criticalOrNonCritical !== 'Non Critical',
          isGlobal: preventativeControl.global as boolean,
          name: preventativeControl.value,
          type: 'Preventative' as ControlType,
          isInitial: false,
          category: ControlCategory.SOFT,
        };
      }),
    };
  });

  const consequences = bowtieData?.consequences?.map((consequence: BowtieStateConsequenceData) => {
    return {
      id: String(consequence.id),
      name: consequence.value,
      controls: consequence.mitigatingControls.map((mitigatingControl: BowtieConsequenceRecord) => {
        return {
          id: String(mitigatingControl.id),
          isCritical: mitigatingControl.criticalOrNonCritical !== 'Non Critical',
          isGlobal: mitigatingControl.global as boolean,
          name: mitigatingControl.value,
          type: 'Mitigating' as ControlType,
          isInitial: false,
          category: ControlCategory.SOFT,
        };
      }),
    };
  });

  return {
    bowtieCorrectionRequest: {
      bowtie: {
        risk: bowtieData.scenario,
        causes: causes || [],
        consequences: consequences || [],
        hazard: bowtieData.hazard ?? '',
        term: term ?? '',
      },
      control_types: [''],
    },
  };
};

const generateFlowSuggestPayload = (bowtieData: BowtieRequestWithId, term?: string): BowtieSuggestPostApiArg => {
  return {
    bowtieCorrectionRequest: {
      bowtie: {
        ...bowtieData,
        term: term ?? '',
      },
      control_types: [''],
    },
  };
};
