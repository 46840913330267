import { Button, Loader } from '@myosh/odin-components';
import { cloneDeep } from 'lodash';
import { createContext, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppConfigurationQuery } from '../api/enhanced/enhanced-v4-api';
import { BowtieConfiguration } from '../services/common-data-types';

interface ConfigurationContextProps {
  children: React.ReactNode;
}

const ConfigurationContext = createContext<BowtieConfiguration | undefined>(undefined);

// Provides all information needed for Bowtie
export const ConfigurationProvider: React.FC<ConfigurationContextProps> = ({ children }: ConfigurationContextProps) => {
  const navigate = useNavigate();

  const loading = useRef(true);
  const error = useRef(false);
  const configuration = useRef<BowtieConfiguration>();

  const { data, isFetching, isError } = useAppConfigurationQuery();

  if (isError) {
    error.current = true;
    loading.current = false;
  } else if (data && !isFetching) {
    const config = cloneDeep(data);

    configuration.current = config;
    error.current = false;
    loading.current = false;
  }

  const containerStyles =
    'bt-mx-auto bt-my-0 bt-flex bt-h-screen bt-w-full bt-flex-col bt-items-center bt-justify-center';

  if (error.current) {
    return (
      <div className={containerStyles}>
        <span>
          Unable to load the bowtie configuration. Please check your configuration or reload the page to try again.
        </span>
        <Button type="primary" onClick={() => navigate(0)} style={{ marginTop: '12px' }}>
          Reload
        </Button>
      </div>
    );
  } else if (loading.current) {
    return (
      <div className={containerStyles}>
        <Loader title={'Loading diagram configuration'} />
      </div>
    );
  } else {
    return <ConfigurationContext.Provider value={configuration.current}>{children}</ConfigurationContext.Provider>;
  }
};

// context consumer hook
export const useConfigurationContext = () => {
  const context = useContext(ConfigurationContext);

  if (context === undefined) {
    throw new Error('useConfigurationContext must be used within ConfigurationProvider');
  }

  return context;
};
