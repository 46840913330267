import { OdinIcon, OdinIconType } from '@myosh/odin-components';
import { MouseEvent, ReactNode, useEffect, useId, useState } from 'react';
import { cn } from '../../helpers/util';

interface AccordionProps {
  title: string;
  children: ReactNode;
  isOpen?: boolean;
  onClick?: (isOpen: boolean) => void;
}

function Accordion({ title, children, isOpen, onClick }: AccordionProps) {
  const contentWrapperId = useId();
  const [open, setOpen] = useState(isOpen);

  const onAccordionClick = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    onClick?.(!open);
    setOpen(!open);
  };

  useEffect(() => {
    if (isOpen !== open) {
      setOpen(isOpen);
    }
  }, [isOpen]);

  return (
    <div className="bt-mb-4 bt-flex bt-w-full bt-flex-col">
      <a
        href={`#${contentWrapperId}`}
        className={
          'bt-flex bt-border-b bt-border-solid bt-border-gray-2 focus:-bt-mb-0.5 focus:bt-border-b-2 focus:bt-border-primary-3 focus:bt-outline-none'
        }
        onClick={onAccordionClick}
      >
        <div className="bt-grow bt-text-sm bt-font-bold bt-leading-9">{title}</div>
        <div className="bt-flex bt-shrink bt-items-center bt-pr-px">
          <OdinIcon type={OdinIconType.Line} icon={open ? 'ArrowUpS' : 'ArrowDownS'} />
        </div>
      </a>
      <div id={contentWrapperId} className={cn('bt-grow bt-pt-5', { 'bt-hidden': !open })}>
        {children}
      </div>
    </div>
  );
}

export default Accordion;
