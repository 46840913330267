import { useUserPermissionsContext } from '../../../../context/user-permissions.context';
import diagramActions from '../../../../redux/diagram/diagramActions';
import { useAppDispatch } from '../../../../redux/hooks';
import Controls, { ContainerType } from '../controls.component';
import LoadingPreview from '../loading-preview';

interface CausesProps {
  isLoading: boolean;
}

const Causes = ({ isLoading }: CausesProps) => {
  const dispatch = useAppDispatch();
  const userPermissions = useUserPermissionsContext();
  const canCreateCauses = userPermissions.createRecord.causes;

  const addCauseInput = () => {
    dispatch(diagramActions.doAddCauseInput());
  };

  return (
    <Controls
      id="causes-container"
      type={ContainerType.CAUSES}
      onAdd={isLoading || !canCreateCauses ? undefined : addCauseInput}
    >
      {isLoading ? (
        <LoadingPreview message="Causes will be displayed here" />
      ) : canCreateCauses ? (
        'Add causes in this box'
      ) : (
        'Causes will be displayed here'
      )}
    </Controls>
  );
};

export default Causes;
