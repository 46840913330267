import { useMemo } from 'react';
import { BowtieRequestWithId } from '../api/generated/ai-api';
import { BowtieDto } from '../api/generated/v4-api';
import AiAnalysisSuggestionContainer from '../components/common/ai-analysis-suggestion-container';
import MainHeader from '../components/header/header.component';
import { DiagramContextProvider as LegacyDiagramContext } from '../context/diagram.context';
import { useAppSelector } from '../redux/hooks';
import { selectCauses, selectConsequences, selectDiagramDisabled, selectHazard, selectMue } from '../redux/slices/diagram';
import { CauseDiagramNode, ConsequenceDiagramNode, DiagramNode } from './@types/diagram';
import useMainHooks from './hooks/use-main-hooks';
import { CriticalControlEnum } from './util/node-util';

// The header component used in the flow diagram
const Header = () => {
  const { analysisRef, isAIGenerated, isNewDiagram, onAIAnalysisReady } = useMainHooks();

  // NOTE: should be simplified once we take flow diagrams into use
  const mue = useAppSelector(selectMue);
  const hazard = useAppSelector(selectHazard);
  const causes = useAppSelector(selectCauses);
  const consequences = useAppSelector(selectConsequences);
  const disableDiagram = useAppSelector(selectDiagramDisabled);

  const mueLabel = mue.label;
  const mueRecordId = mue.recordId;

  const bowtieDtoCreate = useMemo(
    () =>
      hazard
        ? createBowtieDto(mue, hazard, causes as Array<CauseDiagramNode>, consequences as Array<ConsequenceDiagramNode>)
        : undefined,
    [mue, hazard, causes, consequences]
  );

  const bowtieDtoAnalyze = useMemo(
    () =>
      hazard
        ? analyzeBowtieDto(
            mue,
            hazard,
            causes as Array<CauseDiagramNode>,
            consequences as Array<ConsequenceDiagramNode>
          )
        : undefined,
    [mue, hazard, causes, consequences]
  );

  return (
    <div className="bt-relative bt-flex bt-w-full bt-flex-col">
      {disableDiagram && <div className="bt-absolute bt-z-[1301] bt-h-full bt-w-full bt-bg-transparent" />}
      <LegacyDiagramContext isAIGenerated={isAIGenerated} isNewDiagram={isNewDiagram}>
        <MainHeader
          onAIAnalysisReady={onAIAnalysisReady}
          className="bt-mt-0 bt-h-14 bt-pt-2"
          canCreateBackendRecords={!mue.recordId}
          bowtieDtoCreate={bowtieDtoCreate}
          bowtieDtoAnalyze={bowtieDtoAnalyze}
          mueLabel={mueLabel}
          mueRecordId={mueRecordId}
        />
      </LegacyDiagramContext>
      <AiAnalysisSuggestionContainer ref={analysisRef} />
    </div>
  );
};

export default Header;

//temporary utilities to ensure the header works with flow diagrams
const createBowtieDto = (
  mue: DiagramNode,
  hazard: DiagramNode,
  causes: Array<CauseDiagramNode>,
  consequences: Array<ConsequenceDiagramNode>
) => {
  return {
    hazard: hazard.label,
    riskScenario: mue.label,
    causes: causes
      .filter((cause) => cause.label)
      .map((cause) => ({
        cause: cause.label,
        controls: cause.controls.filter((control) => control.label).map((control) => ({ name: control.label })),
      })),
    consequences: consequences
      .filter((consequence) => consequence.label)
      .map((consequence) => ({
        consequence: consequence.label,
        controls: consequence.controls.filter((control) => control.label).map((control) => ({ name: control.label })),
      })),
  } as BowtieDto;
};

const analyzeBowtieDto = (
  mue: DiagramNode,
  hazard: DiagramNode,
  causes: Array<CauseDiagramNode>,
  consequences: Array<ConsequenceDiagramNode>
) => {
  return {
    term: '',
    hazard: hazard.label ?? '',
    risk: mue.label ?? '',
    causes: causes
      .filter((cause) => cause.label)
      .map((cause) => ({
        id: cause.id,
        name: cause.label,
        controls: cause.controls
          .filter((control) => control.label)
          .map((control) => ({
            id: control.id,
            name: control.label,
            isGlobal: control.global,
            isCritical: control.criticalControlType === CriticalControlEnum.CRITICAL,
            isInitial: false,
            category: 'Soft',
            type: 'Preventative',
          })),
      })),
    consequences: consequences
      .filter((consequence) => consequence.label)
      .map((consequence) => ({
        id: consequence.id,
        name: consequence.label,
        controls: consequence.controls
          .filter((control) => control.label)
          .map((control) => ({
            id: control.id,
            name: control.label,
            isGlobal: control.global,
            isCritical: control.criticalControlType === CriticalControlEnum.CRITICAL,
            isInitial: false,
            category: 'Soft',
            type: 'Mitigating',
          })),
      })),
  } as BowtieRequestWithId;
};
