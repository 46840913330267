import { AuthProvider, UserProvider } from '@myosh/myosh-login';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RoutesComponent from './components/routing/routes.component';

function App() {
  return (
    <Router>
      <AuthProvider>
        <UserProvider>
          <RoutesComponent />
        </UserProvider>
      </AuthProvider>
      <ToastContainer position="top-center" hideProgressBar />
    </Router>
  );
}

export default App;
