import { useUser } from '@myosh/myosh-login';
import { Loader } from '@myosh/odin-components';
import React, { createContext, useContext, useRef } from 'react';
import { useGetBowtieConfigurationQuery, useGetSimpleUsersQuery } from '../api/enhanced/enhanced-v4-api';
import { BowtieConfiguration } from '../services/common-data-types';
import { useConfigurationContext } from './configuration.context';

interface UserPermissions {
  createRecord: Record<string, boolean>;
  editRecord: Record<string, boolean>;
  aiEnabled: boolean;
  isSuperUser: boolean;
}

const initialPermissions: UserPermissions = {
  createRecord: {},
  editRecord: {},
  isSuperUser: false,
  aiEnabled: false,
};

const UserPermissionsContext = createContext<UserPermissions | undefined>(undefined);

interface UserPermissionsProviderProps {
  children: React.ReactNode;
}

export const UserPermissionsProvider: React.FC<UserPermissionsProviderProps> = ({
  children,
}: UserPermissionsProviderProps) => {
  const {
    state: { user: { prefered_username: username, superuser } = {} },
  } = useUser();
  const isSuperUser = superuser === 'true';

  const bowtieConfiguration = useConfigurationContext();
  const permissionsRef = useRef<UserPermissions>({ ...initialPermissions, isSuperUser });

  const { data: users, isLoading } = useGetSimpleUsersQuery(
    { filter: `userId:eq_case_insensitive:${username}`, fields: 'groups' },
    { skip: isSuperUser || !username }
  );

  const { data: bowtieConfig, isFetching: isConfigFetching } = useGetBowtieConfigurationQuery();

  if (isSuperUser || (!isSuperUser && !isLoading && Array.isArray(users) && users.length === 1)) {
    permissionsRef.current = {
      ...permissionsRef.current,
      createRecord: {},
      editRecord: {},
    };

    const userRoles = isSuperUser
      ? []
      : (users![0].groups
          ?.map((group) => {
            // due to the API schema being inconsistent, we need to do the following checks
            // group.caption.translations[0].value
            if (
              'caption' in group &&
              group.caption &&
              'translations' in group.caption &&
              Array.isArray(group.caption.translations)
            ) {
              return group.caption.translations[0].value as string;
            }
          })
          .filter((role) => role !== undefined) ?? []);

    if (userRoles.length > 0 || isSuperUser) {
      Object.keys(bowtieConfiguration).forEach((key) => {
        const form = bowtieConfiguration[key as keyof BowtieConfiguration].form;

        if (form.createRecordRoles && form.createRecordRoles.length > 0) {
          const formCreateRoles = form.createRecordRoles.map((role) => role.caption);

          const userHasRole =
            isSuperUser || formCreateRoles.some((formCreateRole) => userRoles.includes(formCreateRole));

          permissionsRef.current!.createRecord = {
            ...permissionsRef.current!.createRecord,
            [key]: userHasRole,
          };
        } else {
          permissionsRef.current!.createRecord = {
            ...permissionsRef.current!.createRecord,
            [key]: false,
          };
        }
      });

      Object.keys(bowtieConfiguration).forEach((key) => {
        const form = bowtieConfiguration[key as keyof BowtieConfiguration].form;
        if (form.editRecordRoles && form.editRecordRoles.length > 0) {
          const formCreateRoles = form.editRecordRoles.map((role) => role.caption);

          const userHasRole =
            isSuperUser || formCreateRoles.some((formCreateRole) => userRoles.includes(formCreateRole));

          permissionsRef.current!.editRecord = {
            ...permissionsRef.current!.editRecord,
            [key]: userHasRole,
          };
        } else {
          permissionsRef.current!.editRecord = {
            ...permissionsRef.current!.editRecord,
            [key]: false,
          };
        }
      });
    }
  }

  if (!isConfigFetching && bowtieConfig) {
    permissionsRef.current.aiEnabled = Boolean(bowtieConfig?.aiEnabled);
  }

  return isLoading || isConfigFetching ? (
    <div className="bt-mx-auto bt-flex bt-h-screen bt-w-full bt-flex-col bt-items-center bt-justify-center">
      <Loader title="Loading permissions" />
    </div>
  ) : (
    <UserPermissionsContext.Provider value={permissionsRef.current}>{children}</UserPermissionsContext.Provider>
  );
};

// context consumer hook
export const useUserPermissionsContext = () => {
  const context = useContext(UserPermissionsContext);

  if (context === undefined) {
    throw new Error('useUserPermissionsContext must be used within a UserPermissionsProvider');
  }

  return context;
};
