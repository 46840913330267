import { ChangeEvent, forwardRef, Ref, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Id, toast } from 'react-toastify';
import { useBowtieConvertImageJobIdGetQuery } from '../../api/enhanced/enhanced-ai-api';
import { BodyBowtieConvertImagePost, useBowtieConvertImagePostMutation } from '../../api/generated/ai-api';
import { useLazyGetSimpleOptionsForFieldQuery } from '../../api/generated/v4-api';
import { useConfigurationContext } from '../../context/configuration.context';

const maxUploadFileSize = 10485760; // 10 MB
interface ImportBowtieDiagramProps {
  acceptedFileTypes?: string[];
}

const ImportBowtieDiagram = (
  { acceptedFileTypes = ['image/jpg', 'image/jpeg', 'image/png'] }: ImportBowtieDiagramProps,
  ref: Ref<HTMLInputElement>
) => {
  const attachmentRef = useRef<File>();
  const infoToastId = useRef<string>();
  const [importImageJobId, setImportImageJobId] = useState('');

  const inputRef = useRef<HTMLInputElement | null>(null);
  useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

  const { pathname } = useLocation();

  const bowtieConfiguration = useConfigurationContext();
  const [startImportBowtie] = useBowtieConvertImagePostMutation();
  const { data: importImageDetails } = useBowtieConvertImageJobIdGetQuery(
    { jobId: importImageJobId },
    {
      skip: !importImageJobId,
      pollingInterval: importImageJobId ? 5000 : 0,
    }
  );

  const [getControlTypes] = useLazyGetSimpleOptionsForFieldQuery();

  const uploadFile = async () => {
    const file = attachmentRef.current;
    if (!file) {
      return;
    }
    // display loading toast
    infoToastId.current = toast.loading(`Processing ${file.name}`) as string;

    // get control types
    const { controlTypeFieldId, formId } = bowtieConfiguration.controls;
    const controlTypes = await getControlTypes({ fieldId: controlTypeFieldId, formId: formId, limit: -1 }).unwrap();

    // create a FormData object to send the file as multipart/form-data
    const formData = new FormData();
    formData.append('image_file', file, file.name);
    formData.append('control_types', JSON.stringify(controlTypes));

    // save job id
    const jobId = await startImportBowtie({
      bodyBowtieConvertImagePost: formData as unknown as BodyBowtieConvertImagePost,
    }).unwrap();

    setImportImageJobId(jobId);
  };

  const onFilesChanged = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      if (!acceptedFileTypes.includes(file.type)) {
        toast.error('Invalid file type, only JPG and PNG files are allowed');
      } else if (file.size > maxUploadFileSize) {
        toast.error('File size exceeds the limit of 10MB');
      } else {
        attachmentRef.current = file;

        try {
          await uploadFile();
        } catch (_) {
          toast.update(infoToastId.current as Id, {
            render: 'Failed to import bowtie, please try again',
            type: 'error',
            isLoading: false,
            autoClose: 2000,
          });
        }

        attachmentRef.current = undefined;
      }
    }
  };

  useEffect(() => {
    if (importImageDetails?.state === 'SUCCESS' || importImageDetails?.state === 'FAILURE') {
      setImportImageJobId('');

      if (importImageDetails?.state === 'SUCCESS') {
        // add to local storage
        const diagramId = crypto.randomUUID();
        localStorage.setItem(
          `importedDiagram-${diagramId}`,
          JSON.stringify({
            ...importImageDetails.result,
            bowtieConfiguration: bowtieConfiguration,
          })
        );

        // open imported diagram and reset file upload
        setTimeout(() => {
          window.open(`${window.location.origin}${pathname}?import=${diagramId}`);
        }, 2500);
        toast.update(infoToastId.current as Id, {
          render: 'Bowtie data imported succesfully',
          type: 'success',
          isLoading: false,
          autoClose: 2000,
        });
      } else {
        toast.update(infoToastId.current as Id, {
          render: 'Failed to import bowtie, please try again',
          type: 'error',
          isLoading: false,
          autoClose: 2000,
        });
      }
    }
  }, [importImageDetails]);

  return (
    <input
      ref={inputRef}
      className="bt-hidden"
      type="file"
      accept={acceptedFileTypes.join(',')}
      onChange={onFilesChanged}
    />
  );
};

export default forwardRef(ImportBowtieDiagram);
