import { useCallback, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { AIAnalysisSuggestionContainerRef } from '../../components/common/ai-analysis-suggestion-container';

function useMainHooks() {
  const analysisRef = useRef<AIAnalysisSuggestionContainerRef>(null);
  let isAIGenerated = false;
  let isNewDiagram = false;

  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);

  if (queryParams.has('term') && queryParams.has('hazard') && queryParams.has('mue')) {
    isAIGenerated = true;
    isNewDiagram = false;
  } else if (queryParams.has('import')) {
    isAIGenerated = true;
    isNewDiagram = false;
  } else if (queryParams.has('records') || queryParams.has('recordId')) {
    isAIGenerated = false;
    isNewDiagram = false;
  } else {
    isAIGenerated = false;
    isNewDiagram = true;
  }

  const onAIAnalysisReady = useCallback((text: string) => {
    analysisRef.current?.show(text);
  }, []);

  return {
    analysisRef,
    isAIGenerated,
    isNewDiagram,
    onAIAnalysisReady,
  };
}

export default useMainHooks;
