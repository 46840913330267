import useAIGenerated from './use-ai-generated';
import useMainHooks from './use-main-hooks';
import useRecord from './use-record';

/**
 * Custom hook for managing diagram data state based on the data source (AI-generated or record).
 *
 * @returns An object containing:
 * - `loading` - Boolean indicating if diagram data is being fetched
 * - `diagramData` - The diagram data, either AI-generated or from record
 *
 * @example
 * ```typescript
 * const { loading, diagramData } = useDiagramData();
 * ```
 */
const useDiagramData = () => {
  const { isAIGenerated } = useMainHooks();

  //   handles a record source
  const { isFetching: isReordFetching, diagramData: recordDiagramData } = useRecord();
  //   handles an AI-generated source
  const { isFetching: isAIFetching, diagramData: aiGeneratedDiagramData } = useAIGenerated();

  const loading =
    (!isAIGenerated && isReordFetching && !recordDiagramData) ||
    (isAIGenerated && isAIFetching && !aiGeneratedDiagramData);

  const diagramData = isAIGenerated ? aiGeneratedDiagramData : recordDiagramData;

  return {
    loading,
    diagramData,
  };
};

export default useDiagramData;
