import React, { forwardRef, Ref, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useDiagramContext } from '../../../context/diagram.context';
import { useUserPermissionsContext } from '../../../context/user-permissions.context';
import { cn } from '../../../helpers/util';
import diagramActions from '../../../redux/diagram/diagramActions';
import { DIAGRAM_ADD_HAZARD_SUCCESS } from '../../../redux/diagram/diagramTypes';
import { useAppDispatch } from '../../../redux/hooks';
import { ContainerType } from './controls.component';
import LoadingPreview from './loading-preview';
import Rectangle from './rectangle/rectangle';

export enum DiagramStatus {
  NEW = 'NEW',
  UPDATE = 'UPDATE',
}

interface HazardProps {
  hazard?: string;
  isLoading: boolean;
  mode: DiagramStatus;
}

const Hazard = ({ hazard, mode, isLoading = false }: HazardProps, forwardRef: Ref<HTMLTextAreaElement>) => {
  const [editHazard, setEditHazard] = useState(DiagramStatus.NEW === mode);
  const [isEditHazardDisabled, setIsEditHazardDisabled] = useState(false);
  const { isAIGenerated } = useDiagramContext();

  const inputRef = useRef<HTMLTextAreaElement>(null);
  useImperativeHandle(forwardRef, () => inputRef.current as HTMLTextAreaElement);

  const dispatch = useAppDispatch();
  const userPermissions = useUserPermissionsContext();
  const canEditHazard = userPermissions.editRecord.main;

  useEffect(() => {
    if (inputRef.current?.focus && editHazard) {
      inputRef.current.focus();
    }
  }, [editHazard]);

  const handleRectangleClick = () => {
    if (canEditHazard) {
      setEditHazard(true);
    }
  };

  const handleOnKeyDown = (event: React.KeyboardEvent) => {
    if ((event.code === 'Enter' || event.code === 'NumpadEnter') && !event.shiftKey) {
      event.preventDefault();
      if (inputRef.current?.value === '') {
        inputRef.current.focus();
      } else {
        inputRef.current?.blur();
      }
    }
  };

  const resetHazardBoxStyles = () => {
    setEditHazard(false);
    setIsEditHazardDisabled(false);
  };

  const addOrUpdateHazard = (hazard: string) => {
    if (isAIGenerated === false) {
      dispatch(
        diagramActions.doAddHazard({
          payload: hazard,
          onHazardDisabled: setIsEditHazardDisabled,
          onEditHazard: setEditHazard,
        })
      );
    } else {
      // update only the state when it's an AI generated diagram
      dispatch({
        type: DIAGRAM_ADD_HAZARD_SUCCESS,
        payload: hazard,
      });
      resetHazardBoxStyles();
    }
  };

  const handleAddHazard = () => {
    if (inputRef.current && inputRef.current.value && inputRef.current.value.trim() !== '') {
      setIsEditHazardDisabled(true);
      addOrUpdateHazard(inputRef.current.value.trim());
    }
  };

  const handleUpdateHazard = async () => {
    if (inputRef.current && inputRef.current.value && inputRef.current.value.trim() !== '') {
      setIsEditHazardDisabled(true);
      if (inputRef.current.value !== hazard) {
        addOrUpdateHazard(inputRef.current.value.trim());
      } else {
        resetHazardBoxStyles();
      }
    } else {
      setEditHazard(false);
      setIsEditHazardDisabled(false);
    }
  };

  // resolve handler based on new/edit mode
  const handleOnBlur = DiagramStatus.NEW === mode ? handleAddHazard : handleUpdateHazard;

  const hazardStyles = cn(
    'bt-relative bt-z-[3000] bt-flex bt-h-[96px] bt-w-[155px] bt-items-center bt-justify-center bt-rounded-lg bt-border-2 bt-border-dashed bt-border-primary-4 bt-bg-gray-5 bt-p-7 bt-text-center bt-text-gray-1 bt-outline-none'
  );

  // element
  if (isLoading) {
    return (
      <div className={cn(hazardStyles, 'bt-mb-32 bt-resize-none bt-flex-col bt-text-sm')}>
        <LoadingPreview message="Hazard will be displayed here" />
      </div>
    );
  } else if (hazard && !editHazard) {
    return <Rectangle id="hazard" type={ContainerType.HAZARD} text={hazard} click={handleRectangleClick} />;
  } else {
    return (
      <textarea
        rows={2}
        id="hazard"
        defaultValue={hazard}
        ref={inputRef}
        disabled={isEditHazardDisabled}
        onKeyDown={handleOnKeyDown}
        placeholder="Click to add Hazard"
        onBlur={handleOnBlur}
        className={cn(hazardStyles, 'bt-mb-32 bt-resize-none bt-text-lg')}
      />
    );
  }
};

export default forwardRef(Hazard);
