import { aiApi as api } from '../base/ai-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    bowtieHazardMueMaterialUnwantedEventsPost: build.mutation<
      BowtieHazardMueMaterialUnwantedEventsPostApiResponse,
      BowtieHazardMueMaterialUnwantedEventsPostApiArg
    >({
      query: (queryArg) => ({
        url: `/bowtie/material-unwanted-events`,
        method: 'POST',
        body: queryArg.hazardMueRequest,
        params: {
          llm_provider: queryArg.llmProvider,
        },
      }),
    }),
    bowtieFactorsPost: build.query<BowtieFactorsPostApiResponse, BowtieFactorsPostApiArg>({
      query: (queryArg) => ({
        url: `/bowtie/factors`,
        method: 'POST',
        body: queryArg.factorRequest,
        params: {
          llm_provider: queryArg.llmProvider,
        },
      }),
    }),
    bowtieSuggestPost: build.mutation<BowtieSuggestPostApiResponse, BowtieSuggestPostApiArg>({
      query: (queryArg) => ({
        url: `/bowtie/suggest`,
        method: 'POST',
        body: queryArg.bowtieCorrectionRequest,
        params: {
          llm_provider: queryArg.llmProvider,
        },
      }),
    }),
    bowtieConvertImagePost: build.mutation<BowtieConvertImagePostApiResponse, BowtieConvertImagePostApiArg>({
      query: (queryArg) => ({
        url: `/bowtie/background-job/convert-image/start`,
        method: 'POST',
        body: queryArg.bodyBowtieConvertImagePost,
      }),
    }),
    bowtieConvertImageJobIdGet: build.query<BowtieConvertImageJobIdGetApiResponse, BowtieConvertImageJobIdGetApiArg>({
      query: (queryArg) => ({ url: `/bowtie/background-job/convert-image/${queryArg.jobId}` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as aiApi };
export type BowtieHazardMueMaterialUnwantedEventsPostApiResponse =
  /** status 200 Successful Response */ HazardMuePair[];
export type BowtieHazardMueMaterialUnwantedEventsPostApiArg = {
  llmProvider?: LlmProvider;
  hazardMueRequest: HazardMueRequest;
};
export type BowtieFactorsPostApiResponse = /** status 200 Successful Response */ Detail;
export type BowtieFactorsPostApiArg = {
  llmProvider?: LlmProvider;
  factorRequest: FactorRequest;
};
export type BowtieSuggestPostApiResponse = /** status 200 Successful Response */ SuggestResponse;
export type BowtieSuggestPostApiArg = {
  llmProvider?: LlmProvider;
  bowtieCorrectionRequest: BowtieCorrectionRequest;
};
export type BowtieConvertImagePostApiResponse = /** status 200 Successful Response */ string;
export type BowtieConvertImagePostApiArg = {
  bodyBowtieConvertImagePost: BodyBowtieConvertImagePost;
};
export type BowtieConvertImageJobIdGetApiResponse =
  /** status 200 Successful Response */ BackgroundJobConvertImageResponse;
export type BowtieConvertImageJobIdGetApiArg = {
  jobId: string;
};
export type HazardMuePair = {
  /** The operation, activity, or material that has one or more material unwanted event that could happen */
  hazard: string;
  /** List of material unwanted event associated with the hazard */
  material_unwanted_event: string[];
};
export type ValidationError = {
  loc: (string | number)[];
  msg: string;
  type: string;
};
export type HttpValidationError = {
  detail?: ValidationError[];
};
export type LlmProvider = 'OPENAI' | 'XAI' | 'AMAZON';
export type HazardMueRequest = {
  term: string;
  extra_prompt?: string | null;
  hazard?: string | null;
};
export type FactorType = 'Cause' | 'Consequence';
export type ControlType = 'Preventative' | 'Mitigating';
export type ControlWithId = {
  /** Control short name */
  name: string;
  /** Control category (Hard or Soft) */
  category: 'Hard' | 'Soft';
  /** Control type (Preventative or Mitigating) */
  type: ControlType;
  /** Indicates whether this control is global */
  isGlobal: boolean;
  /** Indicates whether this control is critical */
  isCritical: boolean;
  /** Indicates whether this control is required to be initially implemented */
  isInitial: boolean;
  /** Control Subtype */
  controlType: string;
  /** Control ID */
  id: string;
  /** Indicates whether this control is a control issue */
  isControlIssues: boolean;
};
export type FactorId = {
  /** Factor ID */
  id: string;
  /** Factor name */
  name: string;
  /** Factor type (Cause or Consequence) */
  type: FactorType;
  /** List of controls related to a factor */
  controls: ControlWithId[];
  /** Factor position */
  position: number;
};
export type Detail = {
  /** The source of a material unwanted event, whether it be an operation, activity, or material. */
  hazard: string;
  /** List of causes of a material unwanted event */
  causes: FactorId[];
  /** List of consequences of a material unwanted event */
  consequences: FactorId[];
};
export type FactorRequest = {
  term: string;
  selected_MUE: string;
  cause_qty?: number | null;
  consequence_qty?: number | null;
  preventative_qty?: number | null;
  mitigation_qty?: number | null;
  control_types?: string[] | null;
  hazard?: string | null;
  extra_prompt?: string | null;
};
export type SuggestResponse = {
  /** Suggested text version to correct the original Bowtie Diagram with what to add, remove, or modify */
  suggestion_text: string;
};
export type ControlRequestWithId = {
  /** Control short name */
  name: string;
  /** Control category (Hard or Soft) */
  category: 'Hard' | 'Soft';
  /** Control type where values are only Preventative or Mitigating, where preventive control is a measure or action that is designed to stop a risk or hazard from occurring in the first place and mitigating control (also referred to as a mitigation or a barrier) is a specific action, procedure, or mechanism that reduces the impact of an event after it has occurred. */
  type: 'Preventative' | 'Mitigating';
  /** Indicates whether this control is global */
  isGlobal: boolean;
  /** Indicates whether this control is critical */
  isCritical: boolean;
  /** Indicates whether this control is required to be initially implemented */
  isInitial: boolean;
  controlType?: string | null;
  id: string;
};
export type CauseRequestWithId = {
  /** Cause short name of a material unwanted event */
  name: string;
  controls: ControlRequestWithId[];
  id: string;
};
export type ConsequenceRequestWithId = {
  /** Consequence short name of a material unwanted event */
  name: string;
  controls: ControlRequestWithId[];
  id: string;
};
export type BowtieRequestWithId = {
  hazard: string;
  risk: string;
  causes: CauseRequestWithId[];
  consequences: ConsequenceRequestWithId[];
  term: string;
};
export type BowtieCorrectionRequest = {
  bowtie: BowtieRequestWithId;
  control_types?: string[] | null;
  extra_prompt?: string | null;
};
export type BodyBowtieConvertImagePost = {
  image_file: Blob;
  control_types?: string;
  extra_prompt?: string;
};
export type BowtieResponse = {
  hazard: string;
  /** The Risk of the term to find the cause and consequence */
  risk: string;
  /** List of causes of a material unwanted event */
  causes: FactorId[];
  /** List of consequences of a material unwanted event */
  consequences: FactorId[];
};
export type BackgroundJobConvertImageResponse = {
  /** The state of the background job */
  state: 'PENDING' | 'STARTED' | 'RETRY' | 'FAILURE' | 'SUCCESS';
  /** The status information detail of the background job */
  status: string;
  /** The result of the background job consisting of the causes and consequences of a material unwanted event, if available */
  result: BowtieResponse | null;
};
export const {
  useBowtieHazardMueMaterialUnwantedEventsPostMutation,
  useBowtieFactorsPostQuery,
  useLazyBowtieFactorsPostQuery,
  useBowtieSuggestPostMutation,
  useBowtieConvertImagePostMutation,
  useBowtieConvertImageJobIdGetQuery,
  useLazyBowtieConvertImageJobIdGetQuery,
} = injectedRtkApi;
