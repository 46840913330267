import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Node, NodeProps, useReactFlow } from '@xyflow/react';
import { memo, useCallback, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { TransformedSimpleRecordDto } from '../../../api/@types/enhanced-v4-api.types';
import { cn } from '../../../helpers/util';
import { useAppDispatch } from '../../../redux/hooks';
import { removeNode, updateNode } from '../../../redux/slices/diagram';
import { ControlDiagramNode } from '../../@types/diagram';
import { ControlNodeTypes, LeafNodeTypes, MutableNodeTypes } from '../../util/node-util';
import Handles, { HandlesProps } from './common/handles.component';
import NodeTextArea from './common/node-text-area.component';
import UnlinkToast from './common/unlink-toast.component';
import LinkIcon from './icons/link-icon.component';
import UnlinkIcon from './icons/unlink-icon.component';

const unlinkConfirmationToastId = 'unlinkConfirmation';

export type BaseNodeData = {
  recordId?: number;
  parentRecordId?: number;
  label?: string;
  linkUrl?: string;
  placeholder?: string;
  className?: string;
  labelClassName?: string;
  iconClassName?: string;
  globalIconClassName?: string;
  rowIndex?: number;
  editMode?: boolean;
  showRecordIndicators?: boolean;
  showUnlinkActionIcon?: boolean;
  draft?: boolean;
  global?: boolean;
  handles?: HandlesProps;
};
type BaseNodeType = Node<BaseNodeData>;
type BaseNodeProps = NodeProps<BaseNodeType>;

/**
 * BaseNode component represents a node in a flow diagram with optional edit mode and various indicators.
 *
 * @component
 * @param {BaseNodeProps} data - The properties for the BaseNode component.
 * @param {string} data.label - The label of the node.
 * @param {string} [data.className] - Additional class names for the node container.
 * @param {string} [data.iconClassName] - Class names for the action icons.
 * @param {string} [data.globalIconClassName] - Class names for the global indicator icon.
 * @param {boolean} [data.editMode=false] - Flag to enable edit mode.
 * @param {boolean} [data.showRecordIndicators=false] - Flag to show record indicators.
 * @param {boolean} [data.draft=false] - Flag to indicate if the node is a draft.
 * @param {boolean} [data.global=false] - Flag to indicate if the node is global.
 * @param {object} [data.handles={}] - Configuration for edge connection targets.
 *
 * @example
 * <BaseNode data={nodeData} />
 *
 * @returns {JSX.Element} The rendered BaseNode component.
 */
const BaseNode = ({ id, type, data }: BaseNodeProps): JSX.Element => {
  const {
    recordId,
    parentRecordId,
    linkUrl,
    label,
    placeholder,
    className,
    labelClassName = 'bt-line-clamp-4',
    iconClassName,
    globalIconClassName,
    rowIndex,
    editMode = false,
    showRecordIndicators = false,
    showUnlinkActionIcon = false,
    draft = false,
    global = false,
    handles = {},
  } = data;

  const inputRef = useRef<HTMLTextAreaElement>(null);

  const { updateNodeData } = useReactFlow();
  const dispatch = useAppDispatch();

  const showLinkActionIcon = Boolean(linkUrl);

  useEffect(() => {
    // dismiss the unlink toast when node renders
    if (toast.isActive(unlinkConfirmationToastId)) {
      toast.dismiss(unlinkConfirmationToastId);
    }
  });

  useEffect(() => {
    if (editMode === true && inputRef.current) {
      // Using only autoFocus on the textarea places the cursor at the beginning of the text
      // (https://github.com/facebook/react/issues/14125)
      const defaultValue = label ?? '';
      inputRef.current.defaultValue = defaultValue;
      inputRef.current.setSelectionRange(defaultValue.length, defaultValue.length);

      // Focus the textarea after component enters edit mode
      setTimeout(() => inputRef.current?.focus(), 0);
    }
  }, [editMode]);

  const updateWithExistingRecord = useCallback(
    (data: Partial<ControlDiagramNode> & { control: TransformedSimpleRecordDto }) => {
      dispatch(
        updateNode({
          id,
          type: type as ControlNodeTypes,
          data: { ...data, editMode: false, parentRecordId },
          rowIndex,
        })
      );
    },
    [id, rowIndex, parentRecordId]
  );

  const handleOnKeyDownEvent = useCallback(
    (event: React.KeyboardEvent, data?: Partial<ControlDiagramNode> & { control: TransformedSimpleRecordDto }) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        if (data) {
          updateWithExistingRecord(data);
        } else {
          const updatedLabel = inputRef.current?.value.trim();
          if (updatedLabel) {
            dispatch(
              updateNode({
                id,
                type: type as MutableNodeTypes,
                data: { label: updatedLabel, editMode: false, recordId, parentRecordId },
                rowIndex,
              })
            );
          }
        }
      } else if (event.code === 'Escape') {
        if (!label && !recordId) {
          dispatchRemoveNode();
        } else {
          updateNodeData(id, { editMode: false });
        }
      }
    },
    [updateNode, updateNodeData, id, label, rowIndex, recordId, parentRecordId, updateWithExistingRecord]
  );

  const handleLinkAction = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();

      window.open(linkUrl);
    },
    [linkUrl]
  );

  const dispatchRemoveNode = useCallback(
    () => dispatch(removeNode({ id, type: type as LeafNodeTypes, data: { ...data, id }, rowIndex })),
    [rowIndex, id, recordId, parentRecordId, type]
  );

  const handleOnBlur = useCallback(() => {
    if (!label && !recordId && editMode === true) {
      dispatchRemoveNode();
    }
  }, [label, recordId, editMode, dispatchRemoveNode]);

  const handleUnlinkAction = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();

      if (recordId) {
        toast((props) => <UnlinkToast {...props} onUnlink={dispatchRemoveNode} control={label!} />, {
          autoClose: 10_000, // auto close after 10 seconds
          toastId: unlinkConfirmationToastId,
        });
      } else {
        dispatchRemoveNode();
      }
    },
    [dispatchRemoveNode, label, recordId]
  );

  return (
    <div
      className={cn(
        'nopan nodrag bt-group bt-flex bt-h-20 bt-w-48 bt-items-center bt-justify-center bt-rounded-md bt-border bt-border-gray-2 bt-p-0.5 bt-text-xs bt-shadow-md',
        className,
        { 'bt-border-dashed bt-bg-transparent': editMode }
      )}
      title={label}
    >
      {!editMode && <div className={cn('bt-text-center', labelClassName)}>{label}</div>}

      {editMode && (
        <NodeTextArea
          ref={inputRef}
          defaultValue={label}
          type={type}
          placeholder={placeholder}
          onKeyDown={handleOnKeyDownEvent}
          onItemClick={updateWithExistingRecord}
          onBlur={handleOnBlur}
        />
      )}

      {/* edge connection targets */}
      <Handles {...handles} />

      {/* draft and global record indicators */}
      {showRecordIndicators && global && (
        <FontAwesomeIcon
          icon={faGlobe}
          className={cn('bt-absolute -bt-bottom-1 -bt-left-1 bt-h-3 bt-w-3', globalIconClassName)}
          title="Global control"
        />
      )}
      {showRecordIndicators && !global && draft && (
        <div
          title="Draft"
          className={cn('bt-absolute -bt-bottom-1 -bt-left-1 bt-h-3 bt-w-3 bt-rounded-full bt-border-2', className)}
        />
      )}
      {/* on hover action icons */}
      {showLinkActionIcon && (
        <div
          title="Open in Viking"
          className={cn(
            'bt-invisible bt-absolute -bt-right-2 -bt-top-2 bt-cursor-pointer group-focus-within:bt-visible group-hover:bt-visible'
          )}
          onClick={handleLinkAction}
        >
          <LinkIcon className={iconClassName} />
        </div>
      )}
      {showUnlinkActionIcon && (
        <div
          title="Unlink"
          className={cn(
            'bt-invisible bt-absolute -bt-bottom-2 -bt-right-2 bt-cursor-pointer group-focus-within:bt-visible group-hover:bt-visible'
          )}
          onClick={handleUnlinkAction}
        >
          <UnlinkIcon className={iconClassName} />
        </div>
      )}
    </div>
  );
};

export default memo(BaseNode);
