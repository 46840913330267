import { useUser } from '@myosh/myosh-login';
import { Navigate, Outlet } from 'react-router-dom';

function SuperuserRoute() {
  const { state } = useUser();
  const isSuperUser = state.user?.superuser === 'true';

  if (isSuperUser) {
    return <Outlet />;
  }
  return <Navigate to="/" replace />;
}

export default SuperuserRoute;
