import { configureStore } from '@reduxjs/toolkit';
import { enhancedAiApi as aiApi } from '../api/enhanced/enhanced-ai-api';
import { enhancedV4Api as v4Api } from '../api/enhanced/enhanced-v4-api';
import legacyDiagramReducer from './diagram/diagramReducer';
import { listenerMiddleware } from './middleware';
import flowDiagram from './slices/diagram';
import filter from './slices/filter';
import runningJobs from './slices/running-jobs';

const store = configureStore({
  reducer: {
    [aiApi.reducerPath]: aiApi.reducer,
    [v4Api.reducerPath]: v4Api.reducer,
    diagram: legacyDiagramReducer,
    flowDiagram,
    filter,
    runningJobs,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(listenerMiddleware.middleware).concat(aiApi.middleware).concat(v4Api.middleware),
});

export default store;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
