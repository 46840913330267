import { useUser } from '@myosh/myosh-login';
import { Navigate, Outlet } from 'react-router-dom';

// This is a temporary route restriction to limit access to the `/flow` path
const RestrictedDiagramRoute = () => {
  const { state } = useUser();
  const { user: { currentSchema } = {} } = state;

  // Allow access to this route only on development or test environment for 'kim' schema
  if (
    ('development' === process.env.NODE_ENV || 'test' === process.env.REACT_APP_ENVIRONMENT) &&
    currentSchema === 'kim'
  ) {
    return <Outlet />;
  }

  return <Navigate to="/" replace />;
};

export default RestrictedDiagramRoute;
