import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { applyEdgeChanges, applyNodeChanges, EdgeChange, NodeChange, type Edge, type Node } from '@xyflow/react';
import { cloneDeep } from 'lodash';
import undoable from 'redux-undo';
import { TransformedSimpleRecordDto } from '../../api/@types/enhanced-v4-api.types';
import { ControlDiagramNode, DiagramConfiguration } from '../../flow/@types/diagram';
import { generateNodesAndEdges } from '../../flow/util/node-edge-generator';
import { LeafNodeTypes, MutableNodeTypes } from '../../flow/util/node-util';
import { RootState } from '../store';

export interface DiagramState extends DiagramConfiguration {
  nodes: Array<Node>;
  edges: Array<Edge>;
  disabled: boolean;
}

// represents a new / empty diagram
const initialState: DiagramState = {
  mue: { id: crypto.randomUUID() },
  causes: [],
  consequences: [],
  nodes: [], // flow diagram nodes
  edges: [], // flow diagram edges
  disabled: false,
};

// The Flow Diagram slice
const diagramSlice = createSlice({
  name: 'flowDiagram',
  initialState,
  reducers: {
    initState: (state, action) => {
      const { mue, hazard, causes, consequences, nodes, edges } = action.payload;
      state.mue = mue;
      state.hazard = hazard;
      state.causes = causes;
      state.consequences = consequences;
      state.nodes = nodes;
      state.edges = edges;
      state.disabled = false;
    },
    // flow diagram action
    onNodesChange: (state, action: PayloadAction<NodeChange<Node>[]>) => {
      state.nodes = applyNodeChanges(action.payload, state.nodes);
    },
    onEdgesChange: (state, action: PayloadAction<EdgeChange<Edge>[]>) => {
      state.edges = applyEdgeChanges(action.payload, state.edges);
    },
    // diagram config actions
    addNode: (state, action: PayloadAction<AddNodeAction>) => {
      _addNode(state, action);
    },
    updateNode: (state, action: PayloadAction<UpdateNodeAction>) => {
      // Remove the 'control' from the node data, if present.
      // The 'control' is used when updating the backend from the listener
      const nodeData: Partial<ControlDiagramNode> = cloneDeep(action.payload.data);
      if ('control' in nodeData) {
        delete nodeData.control;
      }

      _updateNode(state, {
        type: action.type,
        payload: {
          id: action.payload.id,
          type: action.payload.type,
          data: nodeData,
          rowIndex: action.payload.rowIndex,
        },
      });
    },
    removeNode: (state, action: PayloadAction<RemoveNodeAction>) => {
      _removeNode(state, action);
    },
    // This action only exists to update the node data from the listener and avoid a loop
    updateNodeDataFromListener: (state, action: PayloadAction<UpdateNodeAction>) => {
      _updateNode(state, action);
    },
    setDiagramDisabled: (state, action: PayloadAction<boolean>) => {
      state.disabled = action.payload;
    },
  },
});

// actions
export const {
  initState,
  addNode,
  updateNode,
  removeNode,
  updateNodeDataFromListener,
  onNodesChange,
  onEdgesChange,
  setDiagramDisabled,
} = diagramSlice.actions;

// selectors
export const selectNodes = ({ flowDiagram: { present: diagramState } }: RootState) => diagramState.nodes;
export const selectEdges = ({ flowDiagram: { present: diagramState } }: RootState) => diagramState.edges;
export const selectMue = ({ flowDiagram: { present: diagramState } }: RootState) => diagramState.mue;
export const selectHazard = ({ flowDiagram: { present: diagramState } }: RootState) => diagramState.hazard;
export const selectCauses = ({ flowDiagram: { present: diagramState } }: RootState) => diagramState.causes;
export const selectConsequences = ({ flowDiagram: { present: diagramState } }: RootState) => diagramState.consequences;

export const selectCanAddNode = ({ flowDiagram: { present: diagramState } }: RootState) =>
  diagramState.mue.label && diagramState.hazard?.label && !diagramState.mue.editMode;

export const selectDiagramDisabled = ({ flowDiagram: { present: diagramState } }: RootState) => diagramState.disabled;

// reducer with undo/redo functionality
export default undoable(diagramSlice.reducer, { limit: 1 });

// action handlers
interface AddNodeAction {
  type: LeafNodeTypes;
  data: ControlDiagramNode;
  rowIndex?: number;
}

export type UpdateNodeActionData = Partial<ControlDiagramNode> & { control?: TransformedSimpleRecordDto };
export interface UpdateNodeAction {
  id: string;
  type: MutableNodeTypes;
  data: UpdateNodeActionData;
  rowIndex?: number;
}

export interface RemoveNodeAction {
  id: string;
  type: LeafNodeTypes;
  data: ControlDiagramNode;
  rowIndex?: number;
}

const _addNode = (state: DiagramState, action: PayloadAction<AddNodeAction>) => {
  let actionPerformed = false;
  const { type, data: nodeData, rowIndex } = action.payload;

  if (type === 'cause-node') {
    const _rowIndex = rowIndex ?? state.causes.length;
    state.causes.push({ ...nodeData, editMode: true, controls: [], rowIndex: _rowIndex });
    actionPerformed = true;
  } else if (type === 'consequence-node') {
    const _rowIndex = rowIndex ?? state.consequences.length;
    state.consequences.push({ ...nodeData, editMode: true, controls: [], rowIndex: _rowIndex });
    actionPerformed = true;
  } else if (type === 'mitigating-control-node' && rowIndex !== undefined) {
    state.consequences[rowIndex].controls.push({ ...nodeData, editMode: true, rowIndex });
    actionPerformed = true;
  } else if (type === 'preventative-control-node' && rowIndex !== undefined) {
    state.causes[rowIndex].controls.push({ ...nodeData, editMode: true, rowIndex });
    actionPerformed = true;
  }

  if (actionPerformed) {
    // update flow diagram nodes and edges
    const { nodes, edges } = generateNodesAndEdges({
      mue: state.mue,
      hazard: state.hazard,
      causes: state.causes,
      consequences: state.consequences,
    });

    state.nodes = nodes;
    state.edges = edges;
  }
};

const _updateNode = (state: DiagramState, action: PayloadAction<UpdateNodeAction>) => {
  let regenerateNodesAndEdges = false,
    actionPerformed = false;
  const { id, type, data: nodeData, rowIndex } = action.payload;

  if (id === state?.hazard?.id) {
    Object.assign(state.hazard, nodeData);
    actionPerformed = true;
  } else if (id === state.mue.id) {
    Object.assign(state.mue, nodeData);

    if (!state.hazard) {
      // create a new hazard node if it doesn't exist
      state.hazard = { id: crypto.randomUUID(), editMode: true };
      regenerateNodesAndEdges = true;
    }
    actionPerformed = true;
  } else if (type === 'cause-node' && rowIndex !== undefined && state.causes[rowIndex].id === id) {
    Object.assign(state.causes[rowIndex], { ...nodeData, rowIndex });
    actionPerformed = true;
  } else if (type === 'consequence-node' && rowIndex !== undefined && state.consequences[rowIndex].id === id) {
    Object.assign(state.consequences[rowIndex], { ...nodeData, rowIndex });
    actionPerformed = true;
  } else if (type === 'mitigating-control-node' && rowIndex !== undefined) {
    const index = state.consequences[rowIndex].controls.findIndex((control) => control.id === id);
    if (index !== -1 && state.consequences[rowIndex].controls[index].id === id) {
      Object.assign(state.consequences[rowIndex].controls[index], { ...nodeData, rowIndex });
      actionPerformed = true;
    }
  } else if (type === 'preventative-control-node' && rowIndex !== undefined) {
    const index = state.causes[rowIndex].controls.findIndex((control) => control.id === id);
    if (index !== -1 && state.causes[rowIndex].controls[index].id === id) {
      Object.assign(state.causes[rowIndex].controls[index], { ...nodeData, rowIndex });
      actionPerformed = true;
    }
  }

  if (actionPerformed) {
    if (regenerateNodesAndEdges) {
      // this only runs when we add a hazard node in an empty/new diagram
      const { nodes, edges } = generateNodesAndEdges({
        mue: state.mue,
        hazard: state.hazard,
        causes: state.causes,
        consequences: state.consequences,
      });

      state.nodes = nodes;
      state.edges = edges;
    } else {
      // update flow diagram node
      const index = state.nodes.findIndex((node) => node.id === id);
      if (index !== -1) {
        Object.assign(state.nodes[index].data, { ...nodeData, rowIndex });
      }

      // update flow diagram edges
      state.edges = state.edges.map((edge) => {
        if (edge.source === id || edge.target === id) {
          return { ...edge, data: { ...edge.data, parentRecordId: nodeData.recordId } };
        }
        return edge;
      });
    }
  }
};

const _removeNode = (state: DiagramState, action: PayloadAction<RemoveNodeAction>) => {
  let actionPerformed = false;
  const { id, type, rowIndex } = action.payload;

  if (type === 'cause-node' && rowIndex !== undefined && state.causes[rowIndex].id === id) {
    state.causes.splice(rowIndex, 1);
    actionPerformed = true;
  } else if (type === 'consequence-node' && rowIndex !== undefined && state.consequences[rowIndex].id === id) {
    state.consequences.splice(rowIndex, 1);
    actionPerformed = true;
  } else if (type === 'mitigating-control-node' && rowIndex !== undefined) {
    const index = state.consequences[rowIndex].controls.findIndex((control) => control.id === id);
    if (index !== -1 && state.consequences[rowIndex].controls[index].id === id) {
      state.consequences[rowIndex].controls.splice(index, 1);
      actionPerformed = true;
    }
  } else if (type === 'preventative-control-node' && rowIndex !== undefined) {
    const index = state.causes[rowIndex].controls.findIndex((control) => control.id === id);
    if (index !== -1 && state.causes[rowIndex].controls[index].id === id) {
      state.causes[rowIndex].controls.splice(index, 1);
      actionPerformed = true;
    }
  }

  if (actionPerformed) {
    // update flow diagram nodes and edges
    const { nodes, edges } = generateNodesAndEdges({
      mue: state.mue,
      hazard: state.hazard,
      causes: state.causes,
      consequences: state.consequences,
    });

    state.nodes = nodes;
    state.edges = edges;
  }
};
