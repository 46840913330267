import { Button, ModalDialogRef, OdinIcon, OdinIconSize, OdinIconType, SplitButton } from '@myosh/odin-components';
import { memo, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BowtieRequestWithId } from '../../api/generated/ai-api';
import { BowtieDto } from '../../api/generated/v4-api';
import MyoshBowtieLogo from '../../assets/svg/myosh-bowtie-logo.svg';
import { useDiagramContext } from '../../context/diagram.context';
import { useUserPermissionsContext } from '../../context/user-permissions.context';
import { cn } from '../../helpers/util';
import { useAIAnalysis } from '../../hooks/use-ai-analysis';
import { useAppSelector } from '../../redux/hooks';
import { JobStatus, selectHasRunningJobs, selectSubmittedJobs } from '../../redux/slices/running-jobs';
import AiAnalysisConfirmationModal from './ai-analysis-confirmation.modal';
import BowtieWizard, { BowtieWizardRef } from './bowtie-wizard';
import ControlLegend from './control-legend.component';
import CreateRecordsButton from './create-records-button.component';
import ImportBowtieDiagram from './import-bowtie-diagram';
import RecordDropDown from './record-drop-down.component';
import ReloadButton from './reload-button';
import RunningJobsPanel from './running-jobs-panel.component';
import Settings from './settings.component';

interface HeaderProps {
  onAIAnalysisReady: (text: string) => void;
  // optional props are temporary and are used with 'flow' diagrams
  className?: string;
  canCreateBackendRecords?: boolean;
  bowtieDtoCreate?: BowtieDto;
  bowtieDtoAnalyze?: BowtieRequestWithId;
  mueLabel?: string;
  mueRecordId?: number;
}

function Header({
  canCreateBackendRecords,
  className,
  bowtieDtoCreate,
  bowtieDtoAnalyze,
  mueLabel,
  mueRecordId,
  onAIAnalysisReady,
}: HeaderProps) {
  const wizardRef = useRef<BowtieWizardRef>(null);
  const importBowtieDiagramRef = useRef<HTMLInputElement>(null);
  const aiAnalysisConfirmationModalRef = useRef<ModalDialogRef>(null);
  const hasRunningJobs = useAppSelector(selectHasRunningJobs);
  const submittedJobs = useAppSelector(selectSubmittedJobs);
  const latestJobStatus = submittedJobs[0]?.status;

  const { pathname } = useLocation();
  const isFlowDiagram = pathname.includes('flow');

  const userPermissions = useUserPermissionsContext();
  const canCreateBowtie = userPermissions.createRecord.main;
  const hideAiAnalysisConfirmation = localStorage.getItem('hideAiAnalysisConfirmation');

  const { isAIGenerated, isNewDiagram } = useDiagramContext();
  const { runAIAnalysis, isLoading: aiAnalysisRunning } = useAIAnalysis(onAIAnalysisReady, bowtieDtoAnalyze);

  const handleAddBowtie = useCallback(() => {
    window.open(`${window.location.origin}${pathname}`);
  }, []);

  const handleBowtieWizardClick = useCallback(() => {
    return wizardRef.current?.open();
  }, []);

  const handleImportBowtie = useCallback(() => {
    importBowtieDiagramRef.current?.click();
  }, []);

  const handleRunAiAnalysis = () => {
    if (!Boolean(hideAiAnalysisConfirmation)) {
      aiAnalysisConfirmationModalRef.current?.show();
      return;
    }

    runAIAnalysis();
  };

  const handleSubmitConfirmation = (isAiAnalysisConfirmationCheckboxChecked: boolean) => {
    if (isAiAnalysisConfirmationCheckboxChecked) {
      localStorage.setItem('hideAiAnalysisConfirmation', 'true');
    }

    runAIAnalysis();
    aiAnalysisConfirmationModalRef.current?.close();
  };

  const handleSaveAIGeneratedBowtie = () => {
    toast.info('Saving a Bowtie diagram is not yet supported', { autoClose: 2000 });
  };

  const splitButtonItems = [
    {
      label: 'Bowtie AI Wizard',
      command: handleBowtieWizardClick,
      icon: <OdinIcon icon="Magic" type={OdinIconType.Line} size={OdinIconSize.ExtraSmall} className="bt-mr-2" />,
    },
    {
      label: 'Import Bowtie',
      command: handleImportBowtie,
      icon: <OdinIcon icon="Upload" type={OdinIconType.Line} size={OdinIconSize.ExtraSmall} className="bt-mr-2" />,
    },
    {
      label: 'AI Analysis',
      command: handleRunAiAnalysis,
      visible: !isNewDiagram,
      disabled: aiAnalysisRunning,
      icon: <OdinIcon icon="Terminal" type={OdinIconType.Line} size={OdinIconSize.ExtraSmall} className="bt-mr-2" />,
    },
  ];

  const renderBowtieGenerationButton = () => {
    if (canCreateBowtie) {
      if (userPermissions.aiEnabled) {
        return (
          <SplitButton
            label="Add Bowtie"
            onClick={handleAddBowtie}
            model={splitButtonItems}
            variant="alternative"
            className="bt-min-w-52"
            icon={<OdinIcon icon="Add" type={OdinIconType.Line} size={OdinIconSize.ExtraSmall} className="bt-mr-2" />}
          />
        );
      }
      return (
        <Button
          icon={<OdinIcon icon="Add" type={OdinIconType.Line} size={OdinIconSize.ExtraSmall} className="bt-mr-2" />}
          classNames="bt-min-w-52"
          variant="alternative"
          type="primary"
          onClick={handleAddBowtie}
        >
          Add Bowtie
        </Button>
      );
    }
    return null;
  };

  return (
    <>
      <div
        className={cn(
          'bt-r-16 bt-sticky bt-left-0 bt-top-0 bt-z-[1300] bt-mt-2 bt-flex bt-h-12 bt-justify-between bt-bg-mono-1 bt-px-16',
          className
        )}
        id="header"
      >
        <div className="bt-flex bt-flex-row bt-gap-3">
          <img src={MyoshBowtieLogo} alt="myosh-bowtie-logo" />
          {!isAIGenerated && (
            <>
              <RecordDropDown />
              {renderBowtieGenerationButton()}
            </>
          )}
          {(isAIGenerated || (isFlowDiagram && canCreateBackendRecords)) && canCreateBowtie && (
            <div
              className={cn('bt-flex bt-flex-row bt-gap-2', {
                'bt-hidden': hasRunningJobs || latestJobStatus === JobStatus.Success,
              })}
            >
              <CreateRecordsButton bowtieDto={bowtieDtoCreate} mueLabel={mueLabel} mueRecordId={mueRecordId} />
              <Button type="text" variant="alternative" onClick={handleSaveAIGeneratedBowtie}>
                Save
              </Button>
            </div>
          )}
          <RunningJobsPanel submittedJobs={submittedJobs} />
          {!isAIGenerated && !isNewDiagram && <ReloadButton />}
        </div>
        <div className="bt-flex">
          <ControlLegend />
          <Settings />
        </div>
      </div>
      <BowtieWizard ref={wizardRef} />
      <ImportBowtieDiagram ref={importBowtieDiagramRef} />
      <AiAnalysisConfirmationModal
        ref={aiAnalysisConfirmationModalRef}
        handleSubmitConfirmation={handleSubmitConfirmation}
        handleCloseModal={() => aiAnalysisConfirmationModalRef.current?.close()}
      />
    </>
  );
}

export default memo(Header);
