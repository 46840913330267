import React, { forwardRef, Ref, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import diagramActions from '../../redux/diagram/diagramActions';
import { useAppDispatch } from '../../redux/hooks';

interface ZoomControlsProps {
  zoomIn: () => void;
  zoomOut: () => void;
  zoomReset: () => void;
}

export interface ZoomControlsRef {
  getZoomPercentage: () => number;
}

const zoomPercentageContainer =
  'bt-flex bt-h-full bt-w-full bt-items-center bt-justify-between bt-gap-1 bt-px-12 bt-py-0 bt-text-mono-1';
const zoomPercentageClasses = 'bt-border bt-border-gray-3 bt-bg-mono-1 bt-text-gray-3';
const zoomPercentageValueClasses = 'bt-mb-0 bt-px-[5px] bt-py-1 bt-text-xs';
const diagramZoomControls = 'bt-right-0 bt-top-0 bt-z-[4000] bt-mt-3 bt-flex bt-h-[50px] bt-ml-auto bt-px-11 bt-py-0';
const diagramZoomControlsContent = 'bt-ml-auto bt-flex bt-flex-row';
const zoomIcon = 'bt-bg-gray-3 bt-p-1';

const ZoomControls = ({ zoomIn, zoomOut, zoomReset }: ZoomControlsProps, ref: Ref<ZoomControlsRef>) => {
  const [zoomPercentage, setZoomPercentage] = useState(100);
  const dispatch = useAppDispatch();

  useEffect(() => {
    resetZoomPercentage();
  }, []);

  useImperativeHandle(ref, () => ({ getZoomPercentage }));

  const getZoomPercentage = () => zoomPercentage;

  const resetZoomPercentage = useCallback(() => {
    setZoomPercentage(100);
  }, []);

  const resetScrollDiagramLayout = useCallback(() => {
    const mainAreaContainer = document.querySelector('.diagram-transform-container');

    mainAreaContainer?.scrollTo({
      left: 0,
      top: 0,
    });
  }, []);

  const handleZoomIn = () => {
    setZoomPercentage((oldZoomPercentage) => {
      if (oldZoomPercentage < 200) {
        return oldZoomPercentage + 20;
      }
      return oldZoomPercentage;
    });

    zoomIn();
  };

  const handleZoomOut = () => {
    setZoomPercentage((oldZoomPercentage) => {
      if (oldZoomPercentage > 20) {
        return oldZoomPercentage - 20;
      }
      return oldZoomPercentage;
    });

    zoomOut();
  };

  const handleZoomReset = () => {
    resetScrollDiagramLayout();

    setTimeout(() => {
      zoomReset();
      resetZoomPercentage();
      dispatch(
        diagramActions.doUpdateDiagramLayout({
          isWidth: false,
        })
      );
    }, 100);
  };

  return (
    <div className={diagramZoomControls} id="diagram-zoom-controls">
      <div className={diagramZoomControlsContent}>
        <div className={zoomPercentageContainer}>
          <div className={zoomPercentageClasses}>
            <p className={zoomPercentageValueClasses}>{zoomPercentage}%</p>
          </div>
          <div className={zoomIcon} onClick={handleZoomIn}>
            <ZoomPlusIcon />
          </div>
          <div className={zoomIcon} onClick={handleZoomOut}>
            <ZoomMinusIcon />
          </div>
          <div className={zoomIcon} onClick={handleZoomReset}>
            <ZoomResetIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

export default forwardRef(ZoomControls);

// zoom controls loading
export const ZoomControlsLoading = React.memo(() => {
  return (
    <div className={diagramZoomControls} id="diagram-zoom-controls">
      <div className={diagramZoomControlsContent}>
        <div className={zoomPercentageContainer}>
          <div className={zoomPercentageClasses}>
            <p className={zoomPercentageValueClasses}>100%</p>
          </div>
          <div className={zoomIcon}>
            <ZoomPlusIcon />
          </div>
          <div className={zoomIcon}>
            <ZoomMinusIcon />
          </div>
          <div className={zoomIcon}>
            <ZoomResetIcon />
          </div>
        </div>
      </div>
    </div>
  );
});
ZoomControlsLoading.displayName = 'ZoomControlsLoading';

// zoom control panel icons
const ZoomPlusIcon = React.memo(() => (
  <svg width="20" height="20" viewBox="0 0 12 12" className="bt-fill-none">
    <circle cx="6" cy="6" r="5.75" strokeWidth="0.5" className="bt-fill-mono-1 bt-stroke-gray-3" />
    <line x1="9" y1="5.88806" x2="3" y2="5.88806" className="bt-stroke-gray-3" strokeWidth="0.5" />
    <line x1="5.78613" y1="3.15625" x2="5.78613" y2="9.15625" className="bt-stroke-gray-3" strokeWidth="0.5" />
  </svg>
));
ZoomPlusIcon.displayName = 'ZoomPlusIcon';

const ZoomMinusIcon = React.memo(() => (
  <svg width="20" height="20" viewBox="0 0 12 12" className="bt-fill-none">
    <circle cx="6" cy="6" r="5.75" className="bt-fill-mono-1 bt-stroke-gray-3" strokeWidth="0.5" />
    <line x1="9" y1="5.88806" x2="3" y2="5.88806" className="bt-stroke-gray-3" strokeWidth="0.5" />
  </svg>
));
ZoomMinusIcon.displayName = 'ZoomMinusIcon';

const ZoomResetIcon = React.memo(() => (
  <svg width="20" height="20" viewBox="0 0 12 12" className="bt-fill-none">
    <circle cx="6" cy="6" r="5.75" className="bt-fill-mono-1 bt-stroke-gray-3" strokeWidth="0.5" />
  </svg>
));
ZoomResetIcon.displayName = 'ZoomResetIcon';
