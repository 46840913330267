import {
  aiApi,
  BackgroundJobConvertImageResponse,
  BowtieFactorsPostApiArg,
  BowtieFactorsPostApiResponse,
  ControlWithId,
  Detail,
  FactorId,
} from '../generated/ai-api';

const mapControls = (controls: ControlWithId[], parentId: string) => {
  return controls.map((control) => ({
    ...control,
    value: control.name,
    uuid: control.id,
    parentId: parentId,
  }));
};

export const enhancedAiApi = aiApi.enhanceEndpoints({
  endpoints: {
    bowtieConvertImageJobIdGet: {
      transformResponse(response: BackgroundJobConvertImageResponse) {
        return {
          ...response,
          result: {
            risk: response.result?.risk as string,
            hazard: response.result?.hazard as string,
            causes: response?.result?.causes.map((cause) => ({
              ...cause,
              uuid: cause.id,
              value: cause.name,
              controls: mapControls(cause.controls, cause.id),
              preventativeControls: mapControls(cause.controls, cause.id),
            })) as FactorId[],
            consequences: response?.result?.consequences.map((consequence) => ({
              ...consequence,
              uuid: consequence.id,
              value: consequence.name,
              controls: mapControls(consequence.controls, consequence.id),
              mitigatingControls: mapControls(consequence.controls, consequence.id),
            })) as FactorId[],

            scenario: response?.result?.risk,
          },
        };
      },
    },
    bowtieFactorsPost: {
      transformResponse: (response: BowtieFactorsPostApiResponse, _, args: BowtieFactorsPostApiArg) => {
        // align the shape of the data with the existing bowtie data

        const causes = response.causes.map((cause) => {
          const id = crypto.randomUUID();

          const causes = cause.controls.map((control: ControlWithId) => {
            return {
              ...control,
              value: control.name,
              parentId: id,
            };
          });

          return {
            ...cause,
            id: id,
            uuid: id,
            value: cause.name,
            controls: [...causes],
            preventativeControls: [...causes],
          };
        });

        const consequences = response.consequences.map((consequence) => {
          const id = crypto.randomUUID();

          const consequences = consequence.controls.map((control: ControlWithId) => {
            return {
              ...control,
              value: control.name,
              parentId: id,
            };
          });

          return {
            ...consequence,
            id: id,
            uuid: id,
            value: consequence.name,
            controls: [...consequences],
            mitigatingControls: [...consequences],
          };
        });

        return { causes, consequences, hazard: response.hazard ?? args.factorRequest.hazard ?? 'Hazard' } as Detail;
      },
    },
  },
});

export const { useBowtieFactorsPostQuery, useLazyBowtieFactorsPostQuery, useBowtieConvertImageJobIdGetQuery } =
  enhancedAiApi;
