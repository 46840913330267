import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';
import { RootState } from '../store';

export enum JobStatus {
  NotStarted,
  InProgress,
  Success,
  Failed,
}

export interface SubmittedJobDetails {
  status?: JobStatus;
  completedDate?: string;
  url?: string;
  name?: string;
  id: number;
}

interface ExportState {
  submittedJobs: SubmittedJobDetails[];
}

const initialState: ExportState = {
  submittedJobs: [],
};

const runningJobsSlice = createSlice({
  name: 'runningJobs',
  initialState,
  reducers: {
    submitJob: (state, action: PayloadAction<SubmittedJobDetails>) => {
      state.submittedJobs.push({
        status: JobStatus.InProgress,
        ...action.payload,
      });
    },
    setJobStatus: (state, action: PayloadAction<Partial<SubmittedJobDetails>>) => {
      state.submittedJobs = state.submittedJobs.map((job) => {
        if (job.id === action.payload.id) {
          job.status = action.payload.status;
          job.name = action.payload.name ?? job.name;
          job.completedDate = action.payload.completedDate ?? job.completedDate;
          job.url = action.payload.url ?? job.url;
        }

        return job;
      });
    },
    removeJob: (state, action: PayloadAction<number>) => {
      state.submittedJobs = state.submittedJobs.filter((job) => job.id !== action.payload);
    },
  },
});

// actions
export const { submitJob, setJobStatus, removeJob } = runningJobsSlice.actions;

// selectors
const _selectSubmittedJobs = ({ runningJobs }: RootState): SubmittedJobDetails[] => runningJobs.submittedJobs;

export const selectSubmittedJobs = createSelector(_selectSubmittedJobs, (submittedJobs) => {
  return orderBy(
    submittedJobs,
    (job: SubmittedJobDetails) => {
      return new Date(job.completedDate ?? '');
    },
    'desc'
  );
});

export const selectHasRunningJobs = createSelector(_selectSubmittedJobs, (submittedJobs) => {
  return submittedJobs.some((job) => job.status === JobStatus.InProgress);
});

// reducer
export default runningJobsSlice.reducer;
