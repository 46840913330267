import { Node, NodeProps } from '@xyflow/react';
import { useMemo } from 'react';
import BaseNode, { BaseNodeData } from './base-node.component';
import { HandlesProps } from './common/handles.component';

type MueNodeData = BaseNodeData;
export type MueNodeType = Node<MueNodeData>;
type MueNodeProps = NodeProps<MueNodeType>;

const className = 'bt-h-24 bt-w-56 bt-text-lg bt-bg-mue-bg bt-text-mue-fg bt-border-mue-border';
const labelClassName = 'bt-line-clamp-3';
const iconClassName = 'bt-text-mue-bg bt-stroke-mue-fg';
const handles: HandlesProps = {
  topHandleId: 'top',
  topHandleType: 'target',
  leftHandleId: 'left',
  leftHandleType: 'target',
  rightHandleId: 'right',
  rightHandleType: 'target',
};
const staticData = { className, labelClassName, iconClassName, handles };

/**
 * A component that represents a 'material unwanted event' node in a flow diagram.
 * Extends the base node component with mue-specific functionality.
 *
 * @component
 * @param {MUENodeProps} props - The properties for the mue node component
 * @returns {JSX.Element} A rendered mue node component
 *
 * @example
 * ```tsx
 * <MueNode
 *   id="mue"
 *   data={{ label: "MUE" }}
 *   position={{ x: 100, y: 100 }}
 * />
 * ```
 */
const MueNode = (props: MueNodeProps): JSX.Element => {
  const data = useMemo(() => {
    return { ...props.data, ...staticData };
  }, [props.data.label, props.data.editMode]);

  return <BaseNode {...props} data={data} />;
};

export default MueNode;
