import { Button } from '@myosh/odin-components';
import { useCallback } from 'react';
import { ToastContentProps } from 'react-toastify';

interface UnlinkToastProps extends ToastContentProps {
  control: string;
  onUnlink: () => void;
}

const UnlinkToast = ({ control, onUnlink, closeToast }: UnlinkToastProps) => {
  const handleUnlink = useCallback(() => {
    onUnlink();
    closeToast();
  }, [onUnlink, close]);

  return (
    <div className="bt-flex bt-flex-col">
      <div className="bt-whitespace-pre-line bt-py-4">{`Are you sure you want to unlink \n"${control}"?`}</div>
      <div className="bt-flex bt-flex-row-reverse bt-gap-2">
        <Button type="primary" onClick={handleUnlink}>
          Unlink
        </Button>
        <Button type="text" onClick={closeToast}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default UnlinkToast;
