import { Ref, forwardRef, useRef } from 'react';
import diagramSelectors from '../../redux/diagram/diagramSelectors';
import { useAppSelector } from '../../redux/hooks';
import { selectDiagramMode } from '../../redux/slices/filter';
import { DIAGRAM_MODE } from '../../services/bowtie-data-types';
import Causes from './shared/causes/causes';
import CausesExisting from './shared/causes/causes-existing';
import Consequences from './shared/consequences/consequences';
import ConsequencesExisting from './shared/consequences/consequences-existing';
import { HazardContainer, MainAreaContainer, RiskScenarioContainer } from './shared/containers';
import Hazard, { DiagramStatus } from './shared/hazard';
import LineSkeleton from './shared/line-skeleton';
import MitigatingControls from './shared/mitigating-controls/mitigating-controls';
import MitigatingControlsExisting from './shared/mitigating-controls/mitigating-controls-existing';
import PreventativeControls from './shared/preventative-controls/preventative-controls';
import PreventativeControlsExisting from './shared/preventative-controls/preventative-controls-existing';
import RiskScenario from './shared/risk-scenario';

interface DiagramProps {
  isLoading: boolean;
  showHazard: boolean;
  makeHazardVisible: () => void;
}

const Diagram = ({ isLoading, showHazard, makeHazardVisible }: DiagramProps, diagramRef: Ref<HTMLDivElement>) => {
  const bowtieData = useAppSelector(diagramSelectors.selectBowtieData);
  const diagramMode = useAppSelector(selectDiagramMode);

  const riskScenarioInputRef = useRef<HTMLTextAreaElement>(null);
  const hazardInputRef = useRef<HTMLTextAreaElement>(null);

  const diagramRender = () => {
    // NEW bowtie (create mode)
    if (!bowtieData.scenario) {
      if (diagramMode === DIAGRAM_MODE.BOWTIE) {
        return (
          <>
            <Causes isLoading={isLoading} />
            <PreventativeControls isLoading={isLoading} />
            <MainAreaContainer>
              <HazardContainer>
                {showHazard && (
                  <Hazard
                    ref={hazardInputRef}
                    hazard={bowtieData.hazard}
                    isLoading={isLoading}
                    mode={DiagramStatus.NEW}
                  />
                )}
              </HazardContainer>
              <RiskScenarioContainer>
                <RiskScenario
                  ref={riskScenarioInputRef}
                  mode={DiagramStatus.NEW}
                  isLoading={isLoading}
                  showHazard={makeHazardVisible}
                  hazardInputRef={hazardInputRef}
                  bowtieData={bowtieData}
                />
              </RiskScenarioContainer>
            </MainAreaContainer>
            <MitigatingControls isLoading={isLoading} />
            <Consequences isLoading={isLoading} />
          </>
        );
      } else {
        // DIAGRAM_MODE.BUTTERFLY
        return (
          <>
            <PreventativeControls isLoading={isLoading} />
            <Causes isLoading={isLoading} />
            <MainAreaContainer>
              <HazardContainer>
                {showHazard && (
                  <Hazard
                    ref={hazardInputRef}
                    hazard={bowtieData.hazard}
                    isLoading={isLoading}
                    mode={DiagramStatus.NEW}
                  />
                )}
              </HazardContainer>
              <RiskScenarioContainer>
                <RiskScenario
                  ref={riskScenarioInputRef}
                  mode={DiagramStatus.NEW}
                  isLoading={isLoading}
                  showHazard={makeHazardVisible}
                  hazardInputRef={hazardInputRef}
                />
              </RiskScenarioContainer>
            </MainAreaContainer>
            <Consequences isLoading={isLoading} />
            <MitigatingControls isLoading={isLoading} />
          </>
        );
      }
    }

    // EXISTING bowtie (edit mode)
    if (diagramMode === DIAGRAM_MODE.BOWTIE) {
      return (
        <>
          {bowtieData && bowtieData.causes && bowtieData.causes.length > 0 ? (
            <CausesExisting bowtieData={bowtieData} />
          ) : (
            <Causes isLoading={isLoading} />
          )}
          {bowtieData && bowtieData.causes && bowtieData.causes.length > 0 ? (
            <PreventativeControlsExisting bowtieData={bowtieData} />
          ) : (
            <PreventativeControls isLoading={isLoading} />
          )}
          <MainAreaContainer>
            <HazardContainer>
              {showHazard && (
                <Hazard
                  ref={hazardInputRef}
                  hazard={bowtieData.hazard}
                  isLoading={isLoading}
                  mode={DiagramStatus.UPDATE}
                />
              )}
            </HazardContainer>
            <RiskScenarioContainer>
              <RiskScenario
                ref={riskScenarioInputRef}
                mode={DiagramStatus.UPDATE}
                isLoading={isLoading}
                defaultRiskScenario={bowtieData.scenario}
                hasCircleRight={Boolean(bowtieData.consequences)}
                hasCircleLeft={Boolean(bowtieData.causes)}
                link={bowtieData.scenarioRecord?.linkUrl}
                showHazard={makeHazardVisible}
                hazardInputRef={hazardInputRef}
                bowtieData={bowtieData}
              />
            </RiskScenarioContainer>
          </MainAreaContainer>
          {bowtieData && bowtieData.consequences && bowtieData.consequences.length > 0 ? (
            <MitigatingControlsExisting bowtieData={bowtieData} />
          ) : (
            <MitigatingControls isLoading={isLoading} />
          )}
          {bowtieData && bowtieData.consequences && bowtieData.consequences.length > 0 ? (
            <ConsequencesExisting bowtieData={bowtieData} />
          ) : (
            <Consequences isLoading={isLoading} />
          )}
        </>
      );
    } else {
      // DIAGRAM_MODE.BUTTERFLY
      return (
        <>
          {bowtieData && bowtieData.causes && bowtieData.causes.length > 0 ? (
            <PreventativeControlsExisting bowtieData={bowtieData} />
          ) : (
            <PreventativeControls isLoading={isLoading} />
          )}
          {bowtieData && bowtieData.causes && bowtieData.causes.length > 0 ? (
            <CausesExisting bowtieData={bowtieData} />
          ) : (
            <Causes isLoading={isLoading} />
          )}
          <MainAreaContainer>
            <HazardContainer>
              {showHazard && (
                <Hazard
                  ref={hazardInputRef}
                  hazard={bowtieData.hazard}
                  isLoading={isLoading}
                  mode={DiagramStatus.UPDATE}
                />
              )}
            </HazardContainer>
            <RiskScenarioContainer>
              <RiskScenario
                ref={riskScenarioInputRef}
                mode={DiagramStatus.UPDATE}
                isLoading={isLoading}
                defaultRiskScenario={bowtieData.scenario}
                hasCircleRight={Boolean(bowtieData.consequences)}
                hasCircleLeft={Boolean(bowtieData.causes)}
                link={bowtieData.scenarioRecord?.linkUrl}
                showHazard={makeHazardVisible}
                hazardInputRef={hazardInputRef}
              />
            </RiskScenarioContainer>
          </MainAreaContainer>
          {bowtieData && bowtieData.consequences && bowtieData.consequences.length > 0 ? (
            <ConsequencesExisting bowtieData={bowtieData} />
          ) : (
            <Consequences isLoading={isLoading} />
          )}
          {bowtieData && bowtieData.consequences && bowtieData.consequences.length > 0 ? (
            <MitigatingControlsExisting bowtieData={bowtieData} />
          ) : (
            <MitigatingControls isLoading={isLoading} />
          )}
        </>
      );
    }
  };

  return (
    <>
      {bowtieData?.scenario && (
        <LineSkeleton
          causes={bowtieData.causes}
          consequences={bowtieData.consequences}
          diagramMode={diagramMode}
          isHazardVisible={showHazard}
        />
      )}
      <div id="diagram" className="bt-relative bt-m-auto bt-flex bt-content-center bt-justify-center" ref={diagramRef}>
        {diagramRender()}
      </div>
    </>
  );
};

export default forwardRef(Diagram);
