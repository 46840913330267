import { Checkbox, ModalDialog, ModalDialogRef } from '@myosh/odin-components';
import { forwardRef, Ref, useState } from 'react';

interface AiAnalysisConfirmationModalProps {
  handleSubmitConfirmation: (value: boolean) => void;
  handleCloseModal: () => void;
}

const AiAnalysisConfirmationModal = (
  { handleSubmitConfirmation, handleCloseModal }: AiAnalysisConfirmationModalProps,
  forwardRef: Ref<ModalDialogRef>
) => {
  const [isAiAnalysisConfirmationCheckboxChecked, setIsAiAnalysisConfirmationCheckboxChecked] = useState(false);

  return (
    <ModalDialog
      buttons={[
        {
          name: 'submit',
          text: 'Ok',
          type: 'primary',
          onClick: () => handleSubmitConfirmation(isAiAnalysisConfirmationCheckboxChecked),
        },
        {
          name: 'cancel',
          text: 'Cancel',
          type: 'default',
          variant: 'alternative',
          onClick: handleCloseModal,
        },
      ]}
      header={''}
      shouldCheckZIndex={true}
      ref={forwardRef}
    >
      <div className="bt-flex bt-flex-col bt-gap-4">
        <p className="bt-text-lg bt-font-semibold">
          Warning: Data is sent to an external AI. Verify all AI results for accuracy.
        </p>
        <Checkbox
          name="show-ai-analysis-confirmation"
          label={'Don’t show me again'}
          initialChecked={false}
          onChange={(value) => setIsAiAnalysisConfirmationCheckboxChecked(value!.checked)}
        />
      </div>
    </ModalDialog>
  );
};

export default forwardRef(AiAnalysisConfirmationModal);
