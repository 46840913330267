import { memo } from 'react';
import { cn } from '../../helpers/util';

const style = 'bt-h-2 bt-w-2 bt-rounded-full bt-bg-gray-2';

interface LoadingControlsProps {
  className?: string;
}

const LoadingControls = ({ className }: LoadingControlsProps) => {
  return (
    <div className={cn('bt-pointer-events-none bt-flex bt-items-center bt-justify-center bt-space-x-1', className)}>
      <span className={cn('bt-animate-pulse', style)} />
      <span className={cn('bt-animate-pulse-short', style)} />
      <span className={cn('bt-animate-pulse-long', style)} />
    </div>
  );
};

export default memo(LoadingControls);
