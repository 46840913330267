import { v4Api as api } from '../base/v4-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTheme: build.query<GetThemeApiResponse, GetThemeApiArg>({
      query: () => ({ url: `/v4/theme` }),
    }),
    createSimpleRecords: build.mutation<CreateSimpleRecordsApiResponse, CreateSimpleRecordsApiArg>({
      query: (queryArg) => ({
        url: `/v4/records/simple`,
        method: 'POST',
        body: queryArg.body,
        params: {
          params: queryArg.params,
        },
      }),
    }),
    getSimpleRecord: build.query<GetSimpleRecordApiResponse, GetSimpleRecordApiArg>({
      query: (queryArg) => ({
        url: `/v4/records/simple/${queryArg.id}`,
        params: {
          params: queryArg.params,
        },
      }),
    }),
    patchSimple: build.mutation<PatchSimpleApiResponse, PatchSimpleApiArg>({
      query: (queryArg) => ({
        url: `/v4/records/simple/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.simpleRecordDto,
        params: {
          params: queryArg.params,
        },
      }),
    }),
    getBowtieConfiguration: build.query<GetBowtieConfigurationApiResponse, GetBowtieConfigurationApiArg>({
      query: () => ({ url: `/v4/bowtie/configuration` }),
    }),
    setBowtieConfiguration: build.mutation<SetBowtieConfigurationApiResponse, SetBowtieConfigurationApiArg>({
      query: (queryArg) => ({ url: `/v4/bowtie/configuration`, method: 'PUT', body: queryArg.bowtieConfigDto }),
    }),
    getSimpleOptionsForField: build.query<GetSimpleOptionsForFieldApiResponse, GetSimpleOptionsForFieldApiArg>({
      query: (queryArg) => ({
        url: `/v4/fields/${queryArg.fieldId}/options/simple`,
        params: {
          formId: queryArg.formId,
          hierarchyFieldId: queryArg.hierarchyFieldId,
          dependsOn: queryArg.dependsOn,
          recordId: queryArg.recordId,
          keyValue: queryArg.keyValue,
          limit: queryArg.limit,
          filter: queryArg.filter,
          next: queryArg.next,
        },
      }),
    }),
    createAndStartBowtieRecordsCreationJob: build.mutation<
      CreateAndStartBowtieRecordsCreationJobApiResponse,
      CreateAndStartBowtieRecordsCreationJobApiArg
    >({
      query: (queryArg) => ({
        url: `/v4/background-jobs/create-bowtie-records`,
        method: 'POST',
        body: queryArg.bowtieDto,
      }),
    }),
    getSimpleUsers: build.query<GetSimpleUsersApiResponse, GetSimpleUsersApiArg>({
      query: (queryArg) => ({
        url: `/v4/users/simple`,
        params: {
          page: queryArg.page,
          pageSize: queryArg.pageSize,
          fields: queryArg.fields,
          filter: queryArg.filter,
          sort: queryArg.sort,
          fullName: queryArg.fullName,
          logicalOperator: queryArg.logicalOperator,
        },
      }),
    }),
    getSimpleCurrentUser: build.query<GetSimpleCurrentUserApiResponse, GetSimpleCurrentUserApiArg>({
      query: (queryArg) => ({
        url: `/v4/users/current-user/simple`,
        params: {
          fields: queryArg.fields,
        },
      }),
    }),
    getEnvironment: build.query<GetEnvironmentApiResponse, GetEnvironmentApiArg>({
      query: () => ({ url: `/v4/schemas/current` }),
    }),
    getSimpleRecords1: build.query<GetSimpleRecords1ApiResponse, GetSimpleRecords1ApiArg>({
      query: (queryArg) => ({
        url: `/v4/forms/${queryArg.formId}/records/simple`,
        params: {
          page: queryArg.page,
          pageSize: queryArg.pageSize,
          filter: queryArg.filter,
          sort: queryArg.sort,
          sort_directive: queryArg.sortDirective,
          order_by: queryArg.orderBy,
          includeTotal: queryArg.includeTotal,
          fetchLinkedRecords: queryArg.fetchLinkedRecords,
          includeAllFields: queryArg.includeAllFields,
          keyType: queryArg.keyType,
          hierarchyValueType: queryArg.hierarchyValueType,
          captionValuesBase64encoded: queryArg.captionValuesBase64Encoded,
          globalHierarchyMatch: queryArg.globalHierarchyMatch,
        },
      }),
    }),
    getBackgroundJobDetails: build.query<GetBackgroundJobDetailsApiResponse, GetBackgroundJobDetailsApiArg>({
      query: (queryArg) => ({ url: `/v4/background-jobs/${queryArg.id}` }),
    }),
    getAddon1: build.query<GetAddon1ApiResponse, GetAddon1ApiArg>({
      query: (queryArg) => ({ url: `/v4/add-ons/${queryArg.id}` }),
    }),
    getAddonMappings: build.query<GetAddonMappingsApiResponse, GetAddonMappingsApiArg>({
      query: (queryArg) => ({
        url: `/v4/add-ons/mappings`,
        params: {
          filter: queryArg.filter,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as v4Api };
export type GetThemeApiResponse = /** status 200 OK */ {
  [key: string]: string;
};
export type GetThemeApiArg = void;
export type CreateSimpleRecordsApiResponse = /** status 200 OK */ EntityResponseDtoListRecordResponseDto;
export type CreateSimpleRecordsApiArg = {
  /** This parameter supports the following options:
    
    1. `holdNotifications` - this controls whether to send notifications to affected users. These notifications cannot be sent later. Default is false.
    1. `validation` =
        - not specified (default) - allow records with unrecognised options to be imported, but leave the field blank when an unrecognised option is specified.
        - `strict` - validate to ensure submitted options exist. Do not import the record if the option does not exist. Instead, return a validation failure message ('No form found for record id: xxx'), and include details of the field and value that is not recognised.
        - `create` - create unrecognised options for all field types. Leave it up to the end user to manually archive the relevant ones.
        - `createAndArchive` - Auto create unrecognised options and automatically mark them as archived after they are created. If more records are uploaded with the same option, the existing archived value will be reused.
        > _NOTE:_ Some field types allow end users to add new values. For these fields, unrecognised options will automatically be added regardless of validation type.
    1. `overwriteHistoryField` - determines whether to clear and replace HISTORY field contents with the current payload, or to append content to the existing HISTORY value (default behaviour).Default is false.
    1. `applyDefaultValues` - automatically sets default values on fields when they are omitted or set to blank. Default is false.
    1. `setDerivedValues` - automatically calculates and sets values of fields whose default values are derived from other field values. Default is false.
    1. `keyType` - this controls which type of key will be used for fields. If the value is `id` it will use the field's id as the key, otherwise it will use the field's caption.
    1. `hierarchyValueType` - this controls which type of key will be used for hierarchy values. If the value is `id` it will use the hierarchy value id as the key, otherwise it will use the hierarchy value caption.
     */
  params: {
    [key: string]: string;
  };
  body: SimpleRecordDto[];
};
export type GetSimpleRecordApiResponse = /** status 200 OK */ EntityResponseDtoSimpleRecordFetchResultDto;
export type GetSimpleRecordApiArg = {
  id: number;
  /** This parameter supports the following options:
    
    1. `includeAllFields` - include all fields in the response body. Default is false.
    1. `fetchLinkedRecords` - this controls whether to fetch the full json object of linked records, or just the linked record id. Default is false.
    1. `version` - this is to control which record version you want to receive by specifying record number.
    1. `keyType` - this controls which type of key will be used for fields. If the value is `id` it will use the field's id as the key, otherwise it will use the field's caption.
    1. `hierarchyValueType` - this controls which type of key will be used for hierarchy values. If the value is `id` it will use the hierarchy value id as the key, otherwise it will use the hierarchy value caption.
     */
  params: {
    [key: string]: string;
  };
};
export type PatchSimpleApiResponse = /** status 200 OK */ EntityResponseDtoLong;
export type PatchSimpleApiArg = {
  id: number;
  /** This parameter supports the following options:
    
    1. `holdNotifications` - this controls whether to send notifications to affected users. These notifications cannot be sent later. Default is false.
    1. `validation` =
        - not specified (default) - allow records with unrecognised options to be imported, but leave the field blank when an unrecognised option is specified.
        - `strict` - validate to ensure submitted options exist. Do not import the record if the option does not exist. Instead, return a validation failure message ('No form found for record id: xxx'), and include details of the field and value that is not recognised.
        - `create` - create unrecognised options for all field types. Leave it up to the end user to manually archive the relevant ones.
        - `createAndArchive` - Auto create unrecognised options and automatically mark them as archived after they are created. If more records are uploaded with the same option, the existing archived value will be reused.
        > _NOTE:_ Some field types allow end users to add new values. For these fields, unrecognised options will automatically be added regardless of validation type.
    1. `overwriteHistoryField` - determines whether to clear and replace HISTORY field contents with the current payload, or to append content to the existing HISTORY value (default behaviour).Default is false.
    1. `applyDefaultValues` - automatically sets default values on fields when they are set to blank. Default is false.
    1. `setDerivedValues` - automatically calculates and sets values of fields whose default values are derived from other field values. Default is false.
    1. `appendLinkedRecords` - when true, linked records will be appended to existing linked records instead of replacing them. Default is false.
    1. `keyType` - this controls which type of key will be used for fields. If the value is `id` it will use the field's id as the key, otherwise it will use the field's caption.
    1. `hierarchyValueType` - this controls which type of key will be used for hierarchy values. If the value is `id` it will use the hierarchy value id as the key, otherwise it will use the hierarchy value caption.
     */
  params: {
    [key: string]: string;
  };
  simpleRecordDto: SimpleRecordDto;
};
export type GetBowtieConfigurationApiResponse = /** status 200 OK */ EntityResponseDtoBowtieConfigDto;
export type GetBowtieConfigurationApiArg = void;
export type SetBowtieConfigurationApiResponse = /** status 200 OK */ EntityResponseDtoBoolean;
export type SetBowtieConfigurationApiArg = {
  bowtieConfigDto: BowtieConfigDto;
};
export type GetSimpleOptionsForFieldApiResponse = /** status 200 OK */ EntityCursorResponseDtoFieldOptionDtoObject;
export type GetSimpleOptionsForFieldApiArg = {
  fieldId: number;
  /** formId is mandatory */
  formId?: number;
  /** hierarchyFieldId is mandatory if lookup type is 'From a Record matched by a hierarchy value' */
  hierarchyFieldId?: number;
  /** The parent option id if applicable */
  dependsOn?: number;
  /** recordId is mandatory if lookup type is 'From a Linked Record' */
  recordId?: number;
  /** keyValue is mandatory if lookup type is 'From a Record matched by a field value', if lookup from any OPTION field(e.g. COMBOBOX, MULTISELECTFIELD, etc), keyValue will be option id */
  keyValue?: string;
  /** The item count returned per page, apply value -1 to get all items */
  limit?: number;
  /** Support caption and archived filter, e.g. filter=caption:eq:xyz, the valid operators are: 'eq', 'neq', 'like', 'start_with', 'eq_case_insensitive', 'eq_case_insensitive', for 'eq', 'like', 'start_with', they are case insensitive. */
  filter?: string[];
  /** If total options are more than one page, 'next' will be returned in result, specify  cursorNext with this value will return next page. 'next' will not be returned if no next page */
  next?: string;
};
export type CreateAndStartBowtieRecordsCreationJobApiResponse =
  /** status 200 OK */ EntityResponseDtoBackgroundJobDetails;
export type CreateAndStartBowtieRecordsCreationJobApiArg = {
  bowtieDto: BowtieDto;
};
export type GetSimpleUsersApiResponse = /** status 200 OK */ Response;
export type GetSimpleUsersApiArg = {
  page?: number;
  pageSize?: number;
  /** Comma separated list of the fields to include in the fields parameter and response payload(some fields will be merged to one field, like `manager(managerId, managerFirstName, managerLastName), localeCode(localeId, localeTag, dateFormat)`) */
  fields?: 'id, firstName, lastName, employeeId, affiliation, phone, occupation, occupationId, secondaryOccupations, email, manager, managerId, managerFirstName, managerLastName, loginRequired, userId, archived, externalUser, activeExternalUser, ssoUser, version, lastChange, localeId, localeTag, dateFormat, userHierarchy, userHierarchyAccess, groups, adminAccess, unidDominoKey, avatar, fullName, vikingAnalytics, vikingAnalyticsContributor, occasionalExternalUser';
  /** Accepts a list of filters.
    The format is `?filter=field:operator:value`, e.g. `firstName:eq:xyz` to show users whose first name is xyz.
    
    For the user fields and hierarchies, it would be using their captions or id as the filter key:
    - For hierarchy, the filter key is `HIERARCHY`:{hierarchyTypeId}, e.g. filter=HIERARCHY:1:eq:xyz, filter=HIERARCHY:1:id_eq:999;
    
     */
  filter?:
    | 'eq'
    | 'neq'
    | 'gt'
    | 'lt'
    | 'gte'
    | 'lte'
    | 'like'
    | ''
    | 'eq_case_insensitive'
    | 'eq_case_sensitive'
    | 'start_with'
    | 'in'
    | 'id_eq'
    | 'id_neq'
    | 'id_in'
    | 'is_blank'
    | 'in_with_blank'
    | 'id_in_with_blank'
    | 'is_archived'
    | 'between';
  /** Comma separated list of the fields to sort by. The order of the fields is significant. By default the order is ascending, and case insensitive for string properties.
    
    To change the sort order of the field prefix the field with '-' to set the sort order to descending.
    
    For example sort by lastname descending would be '-lastname'.The available fields are listed in the 'fields' section. */
  sort?: string[];
  /** Accepts first or last name values and filters the users by that value. */
  fullName?: string;
  /** Accepts a logical operator value, the operator value is used to combine the filters you have specified in the `filter` parameter. Keep in mind that you can use only one value at a time.
    <ul><li>The AND value will display a given user if all filters specified are TRUE.</li><li>The OR value will display a given user if one of the filters is TRUE.</li></ul> */
  logicalOperator?: 'AND' | 'OR';
};
export type GetSimpleCurrentUserApiResponse = /** status 200 OK */ EntityResponseDtoUserRequestDtoObjectRead;
export type GetSimpleCurrentUserApiArg = {
  /** Comma separated list of the fields to include in the fields parameter and response payload(some fields will be merged to one field, like `manager(managerId, managerFirstName, managerLastName), localeCode(localeId, localeTag, dateFormat)`) */
  fields?: 'id, firstName, lastName, employeeId, affiliation, phone, occupation, occupationId, secondaryOccupations, email, manager, managerId, managerFirstName, managerLastName, loginRequired, userId, archived, externalUser, activeExternalUser, ssoUser, version, lastChange, localeId, localeTag, dateFormat, userHierarchy, userHierarchyAccess, groups, adminAccess, unidDominoKey, avatar, fullName, vikingAnalytics, vikingAnalyticsContributor, occasionalExternalUser';
};
export type GetEnvironmentApiResponse = /** status 200 OK */ {
  [key: string]: string;
};
export type GetEnvironmentApiArg = void;
export type GetSimpleRecords1ApiResponse = /** status 200 OK */ EntityResponseDtoRecordFetchResultDtoSimpleRecordDto;
export type GetSimpleRecords1ApiArg = {
  formId: number;
  page?: number;
  pageSize?: number;
  /** Accepts a list of filters.
    The format is `?filter=field:operator:value`, e.g. `deleted:eq:true` to show deleted records.
    
    The valid operators are: 'eq', 'neq', 'gt', 'lt', 'gte', 'lte', 'like', 'eq_case_insensitive', 'start_with', 'in', 'is_blank', 'in_with_blank', 'is_archived',.
    - For 'in', 'in_with_blank' operator, the value should be separated by ','.
    - For 'is_blank' and 'is_archived', the value should be boolean value.
    - For 'in_with_blank', the condition will include blank value.
    - The filter key ("field" in above example) can be categorised into 3 categories: record field, hierarchy and pre-defined keywords.
    
    For all categories, it would be using their captions or id as the filter key(old format), also support new format(`filter={propertyType}[:{typeIdentifier}][:{property}]:{operator}:{value}`) as below:
    - For normal field, it support `FIELD`:{fieldId} as filter key, e.g. FIELD:1:eq:xyz;
    - For record link field, it support `RECORDLINK`:{recordLinkFieldId} + linked field filter format as filter key, e.g. RECORDLINK:99:FIELD:1:eq:xyz;
    - For reversed record link field, it support `REVERSEDRECORDLINK`:{recordLinkFieldId} + linked field filter format as filter key, e.g. REVERSEDRECORDLINK:99:FIELD:1:eq:xyz;
    - For hierarchy, it support `HIERARCHY`:{hierarchyTypeId} as filter key, e.g. HIERARCHY:1:eq:xyz;
    - For workflow step history, it support filter key is `WORKFLOWSTEP`:{workflowStepId}:TRANSITIONDATE as filter key, e.g. WORKFLOWSTEP:1:TRANSITIONDATE:gt:2024-02-14T00:00:00.000;
    - For pre-defined keywords, please refer to below. It support `KEYWORD`:{keyword} as filter key, e.g. KEYWORD:docNo:eq:1;
    - For some filters, it can filter by either id or caption, like COMBOBOX, keyword status, etc, it can add `ID` or `CAPTION` property to make filter more clearly, e.g. FIELD:1:ID:in:1,2,3, FIELD:1:CAPTION:in:x,y,z, KEYWORD:status:ID:1,2, KEYWORD:status:caption:in:Open,Closed;
    - Operators start with 'id'(id_in, id_in_with_blank, id_eq, id_neq) will be deprecated, if want to filter by id, please specify `ID` property as above.
    
    The `filter` parameter can be used as follows:
    - No filters: return all records the user has access to view.
    - Filter by keyword: returns all records the user has access to view that match the specified keyword value. Since keywords are consistent across all forms, only matching records are included.
    For `lastModifiedDateRange` keyword, it will return records which last modified date is in specified date range, support values: `thisMonth, lastThreeMonths, lastSixMonths, thisYear, all`
    - Filter by field: return all records the user has access to that match the form field filter. Some field types (e.g. COMBOBOX, OPTIONGROUP, MULTISELECTFIELD, etc,) support the `in` operator, and the value can either be the field option id or field option caption (Please specify `ID` or `CAPTION` property in field key)
     */
  filter?: 'id, status, workflowStepId, docNo, author, creationDate, lastModifiedDate(deprecated, replaced by lastChange), lastChange, dbLastChange, deleted, archivedStep, excludeNonRecurs, lastModifiedDateRange(deprecated, replaced by lastChangeRange), lastChangeRange, authorId';
  /** Property name to sort by. To sort by multiple properties, separate sort parameter should be used for each property. The order of the properties is significant and values are case insensitive for string properties. By default the order is ascending (`-` prefix for descending, e.g. `-propertyName`). This parameter is mutually exclusive with sort_directive and will throw an error if both are used.
    Besides the field names you can sort by docNo, lastChange, dbLastChange, creationDate and status. When using these 'special fields' be aware that if there's a field in the form with the names mentioned before, the field of the form has priority */
  sort?: string[];
  /** Sort defining "directive". To define multiple directives, separate parameter should be used for each directive.
    The order of the directives is significant. By default the order is ascending, and case insensitive for string properties.
    For descending sort `-` prefix is used (e.g. `-FIELD:100`).
    This parameter is mutually exclusive with sort and will throw an error if both are used.
    The format is `sort_directive={propertyType}[:{typeIdentifier}][:{property}]`
    The directives follow a special notation that allows us to reference different record entities, namely FIELD, KEYWORD, HIERARCHY, QUESTIONNAIRE, etc.
    - For normal field, the format is `FIELD`:{fieldId}; The default reference is FIELD so in this case FIELD:100 and 100 would be equivalent and would both mean sort by field with ID 100
    - For keyword, the new format is `KEYWORD`:{keyword}. The legacy format `KEYWORD`:{keyword Id}. To sort by a keyword with ID 100 you would need to pass KEYWORD:100 as a directive and to make that descending you would pass -KEYWORD:100. The new format will be KEYWORD:status
    - For HIERARCHY, the format is `HIERARCHY`:{hierarchy type id}; The legacy format `HIERARCHY_TYPE`:{hierarchy type id}
    - For QUESTIONNAIRE field, the format is `QUESTIONNAIRE`:{fieldId}:{QUESTIONNAIRE type}; `QUESTIONNAIRE type` can be one of TOTAL_SCORE, AVERAGE_SCORE, MAX_SCORE_POSSIBLE and PERCENT_SCORE. e.g. QUESTIONNAIRE:1:TOTAL_SCORE will sort by TOTAL_SCORE.
    - For GRAND SCORE field, the format is `GRANDSCORE`:{fieldId}:{GRANDSCORE type}; `GRANDSCORE type` can be one of TOTAL_SCORE, MAX_POSSIBLE and PERCENTAGE. e.g. GRANDSCORE:1:PERCENTAGE will sort by PERCENTAGE.
     */
  sortDirective?: string[];
  /** The sort order of the properties defined in sort parameter. */
  orderBy?: 'DESC' | 'ASC';
  includeTotal?: boolean;
  /** Whether to fetch the complete JSON data of linked records, or only the record ids. */
  fetchLinkedRecords?: boolean;
  /** Whether to include all record field value even if the field is removed or value is blank, or only the active field and value has been assigned. */
  includeAllFields?: boolean;
  /** Determine what will be returned as key in fields, if value is 'id', it will return field id as key in fields, otherwise it will return field name as key */
  keyType?: string;
  /** Determine what will be returned as value in hierarchies, if value is 'id', it will return hierarchy value id and caption pair as value in hierarchies, otherwise it will return hierarchy value caption as value */
  hierarchyValueType?: string;
  /** Specifies whether filter values should be treated as base64-encoded. Default is false. If true, it will consider filter value as a base64-encoded string, and it will be decoded in the backend. If you wish to encode filter values, please encode values individually (not the whole filter). e.g. in `filter=field:1:caption:in:a,b,c` the values 'a', 'b', and 'c' need to be encoded individually and separated with commas, i.e. `filer=field:1:caption:in:YQ==,Yg==,Yw==`.
   */
  captionValuesBase64Encoded?: boolean;
  /** Global hierarchy filter match type. May be `all` or `any`. Default is all.  */
  globalHierarchyMatch?: string;
};
export type GetBackgroundJobDetailsApiResponse = /** status 200 OK */ EntityResponseDtoBackgroundJobDetails;
export type GetBackgroundJobDetailsApiArg = {
  id: number;
};
export type GetAddon1ApiResponse = /** status 200 OK */ EntityResponseDtoAddonResultDto;
export type GetAddon1ApiArg = {
  id: number;
};
export type GetAddonMappingsApiResponse = /** status 200 OK */ EntityResponseDtoListAddonMappingDto;
export type GetAddonMappingsApiArg = {
  /** Supported filters:<ul><li>`name` - addon name</li><li>`display_type` - addon display type (which determines where the addon button is being displayed).<br/>Possible values: `RECORD_LIST`, `RECORD` and `RECORD_MORE`.</li></ul> */
  filter?: string[];
};
export type ResponseMessageDto = {
  index?: number;
  code?: string;
  key?: string;
  message?: string;
};
export type Validation = {
  /** the errors that occured while attempting the requested operation */
  errors?: string[];
  /** the warnings that occured while attempting the requested operation */
  warnings?: string[];
  /** the new format errors that occured while attempting the requested operation */
  responseErrors?: ResponseMessageDto[];
  /** the new format warnings that occured while attempting the requested operation */
  responseWarnings?: ResponseMessageDto[];
};
export type RecordResponseDto = {
  success?: boolean;
  id?: number;
  sourceId?: string;
  error?: string;
  warnings?: string[];
};
export type EntityResponseDtoListRecordResponseDto = {
  validation?: Validation;
  result?: RecordResponseDto[];
};
export type UserDto = {
  id?: number;
  value?: string;
  managerId?: number;
  managerValue?: string;
  archived?: boolean;
};
export type AttachmentDto = {
  id?: number;
  name?: string;
  person?: UserDto;
  recordId?: number;
  lastModified?: string;
  sourceId?: string;
  deleted?: boolean;
  fileKey?: string;
  url?: string;
};
export type HierarchyFieldDtoString = {
  id?: number;
  hierarchyTypeId?: number;
  hierarchyTypeCaption?: string;
  parentId?: number;
  caption?: string;
  longitude?: number;
  latitude?: number;
  geofenceEnabled?: boolean;
  geofenceRadius?: number;
  archived?: boolean;
  lastChange?: string;
  deleted?: boolean;
  hierarchyFieldParentCaption?: string;
};
export type SimpleRecordDto = {
  id?: number;
  sourceId?: string;
  moduleId?: number;
  moduleName?: string;
  viewId?: string;
  formId?: number;
  formName?: string;
  status?: string;
  docNo?: number;
  author?: UserDto;
  accountable?: UserDto[];
  creationDate?: string;
  lastModifiedDate?: string;
  lastChange?: string;
  dbLastChange?: string;
  linkUrl?: string;
  vikingLinkUrl?: string;
  deleted?: boolean;
  version?: number;
  displayText?: string;
  title?: string;
  calendarViewDate?: string;
  files?: string[];
  images?: string[];
  deletedFiles?: string[];
  mobile?: {
    [key: string]: string;
  };
  readAccessRoles?: string[];
  childRecordIds?: number[];
  workflowStepHistory?: {
    [key: string]: string;
  };
  attachmentInfo?: AttachmentDto[];
  sourceSystem?: 'ONLINE_LEARNING' | 'DOMINO';
  /** Instead of `hierarchies` hierarchyDetails must be used */
  hierarchies?: {
    [key: string]: object;
  };
  hierarchyDetails?: {
    [key: string]: object;
  };
  fields?: {
    [key: string]: object;
  };
  hierarchyValues?: {
    [key: string]: HierarchyFieldDtoString;
  };
  template?: SimpleRecordDto;
};
export type SimpleRecordFetchResultDto = {
  apiVersion?: string;
  name?: string;
  page?: number;
  hits?: number;
  results?: SimpleRecordDto[];
};
export type EntityResponseDtoSimpleRecordFetchResultDto = {
  validation?: Validation;
  result?: SimpleRecordFetchResultDto;
};
export type EntityResponseDtoLong = {
  validation?: Validation;
  result?: number;
};
export type BowtieConfigDto = {
  aiEnabled: boolean;
};
export type EntityResponseDtoBowtieConfigDto = {
  validation?: Validation;
  result?: BowtieConfigDto;
};
export type EntityResponseDtoBoolean = {
  validation?: Validation;
  result?: boolean;
};
export type FieldOptionDtoObject = {
  id?: number;
  fieldId?: number;
  caption?: object;
  abbreviationCaption?: object;
  parentFieldOptionId?: number;
  defaultOption?: boolean;
  archived?: boolean;
  position?: number;
  color?: string;
};
export type EntityCursorResponseDtoFieldOptionDtoObject = {
  validation?: Validation;
  /** The cursor to include to get the next page, or null if there are no more pages */
  next?: string;
  items?: FieldOptionDtoObject[];
};
export type BackgroundJobDetails = {
  id?: number;
  archived?: boolean;
  presignedUrl?: string;
  status?: string;
  createdDate?: string;
  startDate?: string;
  finishDate?: string;
  archivedDate?: string;
  jobDetails?: string;
  userId?: number;
  moduleFormId?: number;
  moduleId?: number;
  params?: string;
  type?: number;
  clonedRecordIds?: string;
  fileName?: string;
};
export type EntityResponseDtoBackgroundJobDetails = {
  validation?: Validation;
  result?: BackgroundJobDetails;
};
export type BowtieControlDto = {
  id?: number;
  name?: string;
  sourceId?: string;
};
export type BowtieCauseDto = {
  id?: number;
  cause?: string;
  controls?: BowtieControlDto[];
};
export type BowtieConsequenceDto = {
  id?: number;
  consequence?: string;
  controls?: BowtieControlDto[];
};
export type BowtieDto = {
  id?: number;
  riskScenario?: string;
  hazard?: string;
  causes?: BowtieCauseDto[];
  consequences?: BowtieConsequenceDto[];
};
export type Response = {
  validation?: Validation;
};
export type GroupEditAccessDto = {
  allGroups?: boolean;
  groups?: number[];
};
export type WorkflowAccessDto = {
  allModules?: boolean;
  modules?: number[];
};
export type AdminAccessDto = {
  adminAccess?:
    | 'BASIC_FORMBUILDER_READ'
    | 'ADVANCED_FORMBUILDER_READ'
    | 'NOTIFICATIONS_READ'
    | 'HIERARCHIES_READ'
    | 'ROLES_READ'
    | 'USERS_READ'
    | 'RISK_MATRIX_READ'
    | 'MODULES_READ'
    | 'SCHEDULES_READ'
    | 'OCCUPATION_READ'
    | 'TRAINING_MANAGEMENT_READ'
    | 'LICENSING_READ'
    | 'BASIC_FORMBUILDER_CREATE'
    | 'ADVANCED_FORMBUILDER_CREATE'
    | 'NOTIFICATIONS_CREATE'
    | 'HIERARCHIES_CREATE'
    | 'ROLES_CREATE'
    | 'USERS_CREATE'
    | 'MODULES_CREATE'
    | 'SCHEDULES_CREATE'
    | 'OCCUPATION_CREATE'
    | 'TRAINING_MANAGEMENT_CREATE'
    | 'BASIC_FORMBUILDER_MODIFY'
    | 'ADVANCED_FORMBUILDER_MODIFY'
    | 'NOTIFICATIONS_MODIFY'
    | 'HIERARCHIES_MODIFY'
    | 'ROLES_MODIFY'
    | 'USERS_MODIFY'
    | 'RISK_MATRIX_MODIFY'
    | 'MODULES_MODIFY'
    | 'SCHEDULES_MODIFY'
    | 'OCCUPATION_MODIFY'
    | 'TRAINING_MANAGEMENT_MODIFY'
    | 'DASHBOARD_ADMINISTRATE_LAYOUT'
    | 'ADVANCED_FORMBUILDER_DELETE'
    | 'TRAINING_MANAGEMENT_DELETE'
    | 'LOGS_READ'
    | 'MANAGE_ACCOUNT'
    | 'BASIC_FORMBUILDER_DELETE_FIELDS'
    | 'HOME_PAGE_READ'
    | 'HOME_PAGE_MODIFY'
    | 'MODULES_BULK_EDIT'
    | 'ROLES_ADMIN_ACCESS'
    | 'NAME_UPDATES'
    | 'AUTO_RULE'
    | 'TRAINING_MATRIX_READ'
    | 'GAP_ANALYSIS_READ'
    | 'ONLINE_LEARNING_FULL_ADMIN_ACCESS'
    | 'ONLINE_LEARNING_ENROLMENT_ACCESS'
    | 'USER_STATUSES_MODIFY';
  groupEditAccess?: GroupEditAccessDto;
  workflowAccess?: WorkflowAccessDto;
};
export type RoleDtoObject = {
  id?: number;
  caption?: object;
  managerEmail?: string;
  managerLocaleId?: number;
  archived?: boolean;
  externalRoleName?: string;
  version?: number;
  adminAccess?: AdminAccessDto[];
};
export type HierarchyFieldDtoObject = {
  id?: number;
  hierarchyTypeId?: number;
  hierarchyTypeCaption?: object;
  parentId?: number;
  caption?: object;
  longitude?: number;
  latitude?: number;
  geofenceEnabled?: boolean;
  geofenceRadius?: number;
  archived?: boolean;
  lastChange?: string;
  deleted?: boolean;
  hierarchyFieldParentCaption?: object;
};
export type OccupationDtoObject = {
  id?: number;
  caption?: object;
  archived?: boolean;
  roles?: RoleDtoObject[];
  hierarchyFields?: HierarchyFieldDtoObject[];
  external?: boolean;
  sourceId?: string;
};
export type UserRequestDtoObject = {
  id?: number;
  userId?: string;
  firstName?: string;
  lastName?: string;
  employeeId?: string;
  affiliation?: string;
  phone?: string;
  occupation?: OccupationDtoObject;
  secondaryOccupations?: OccupationDtoObject[];
  email?: string;
  manager?: UserRequestDtoObject;
  loginRequired?: boolean;
  ssoUser?: boolean;
  password?: string;
  retypePassword?: string;
  archived?: boolean;
  localeCode?: string;
  groups?: RoleDtoObject[];
  superUser?: boolean;
  splitPosition?: number;
  unid?: string;
  customerId?: number;
  externalUser?: boolean;
  activeExternalUser?: boolean;
  avatar?: string;
  version?: number;
  lastChange?: string;
  userHierarchy?: HierarchyFieldDtoObject[];
  userSecondaryHierarchy?: HierarchyFieldDtoObject[];
  userHierarchyAccessRestriction?: HierarchyFieldDtoObject[];
  userHierarchyFilter?: HierarchyFieldDtoObject[];
  fullName?: string;
  vikingAnalytics?: boolean;
  vikingAnalyticsContributor?: boolean;
  occasionalExternalUser?: boolean;
  passwordChangeRequired?: boolean;
  hierarchyMatchingOption?: 'MATCH_ALL' | 'MATCH_ANY';
  isVikingAnalyticsEnabled?: boolean;
};
export type UserRequestDtoObjectRead = {
  id?: number;
  userId?: string;
  firstName?: string;
  lastName?: string;
  employeeId?: string;
  affiliation?: string;
  phone?: string;
  occupation?: OccupationDtoObject;
  secondaryOccupations?: OccupationDtoObject[];
  email?: string;
  manager?: UserRequestDtoObjectRead;
  loginRequired?: boolean;
  ssoUser?: boolean;
  password?: string;
  retypePassword?: string;
  archived?: boolean;
  localeCode?: string;
  groups?: RoleDtoObject[];
  superUser?: boolean;
  splitPosition?: number;
  unid?: string;
  customerId?: number;
  externalUser?: boolean;
  activeExternalUser?: boolean;
  avatar?: string;
  version?: number;
  lastChange?: string;
  userHierarchy?: HierarchyFieldDtoObject[];
  userSecondaryHierarchy?: HierarchyFieldDtoObject[];
  userHierarchyAccessRestriction?: HierarchyFieldDtoObject[];
  userHierarchyFilter?: HierarchyFieldDtoObject[];
  adminAccess?: (
    | 'BASIC_FORMBUILDER_READ'
    | 'ADVANCED_FORMBUILDER_READ'
    | 'NOTIFICATIONS_READ'
    | 'HIERARCHIES_READ'
    | 'ROLES_READ'
    | 'USERS_READ'
    | 'RISK_MATRIX_READ'
    | 'MODULES_READ'
    | 'SCHEDULES_READ'
    | 'OCCUPATION_READ'
    | 'TRAINING_MANAGEMENT_READ'
    | 'LICENSING_READ'
    | 'BASIC_FORMBUILDER_CREATE'
    | 'ADVANCED_FORMBUILDER_CREATE'
    | 'NOTIFICATIONS_CREATE'
    | 'HIERARCHIES_CREATE'
    | 'ROLES_CREATE'
    | 'USERS_CREATE'
    | 'MODULES_CREATE'
    | 'SCHEDULES_CREATE'
    | 'OCCUPATION_CREATE'
    | 'TRAINING_MANAGEMENT_CREATE'
    | 'BASIC_FORMBUILDER_MODIFY'
    | 'ADVANCED_FORMBUILDER_MODIFY'
    | 'NOTIFICATIONS_MODIFY'
    | 'HIERARCHIES_MODIFY'
    | 'ROLES_MODIFY'
    | 'USERS_MODIFY'
    | 'RISK_MATRIX_MODIFY'
    | 'MODULES_MODIFY'
    | 'SCHEDULES_MODIFY'
    | 'OCCUPATION_MODIFY'
    | 'TRAINING_MANAGEMENT_MODIFY'
    | 'DASHBOARD_ADMINISTRATE_LAYOUT'
    | 'ADVANCED_FORMBUILDER_DELETE'
    | 'TRAINING_MANAGEMENT_DELETE'
    | 'LOGS_READ'
    | 'MANAGE_ACCOUNT'
    | 'BASIC_FORMBUILDER_DELETE_FIELDS'
    | 'HOME_PAGE_READ'
    | 'HOME_PAGE_MODIFY'
    | 'MODULES_BULK_EDIT'
    | 'ROLES_ADMIN_ACCESS'
    | 'NAME_UPDATES'
    | 'AUTO_RULE'
    | 'TRAINING_MATRIX_READ'
    | 'GAP_ANALYSIS_READ'
    | 'ONLINE_LEARNING_FULL_ADMIN_ACCESS'
    | 'ONLINE_LEARNING_ENROLMENT_ACCESS'
    | 'USER_STATUSES_MODIFY'
  )[];
  fullName?: string;
  vikingAnalytics?: boolean;
  vikingAnalyticsContributor?: boolean;
  occasionalExternalUser?: boolean;
  passwordChangeRequired?: boolean;
  hierarchyMatchingOption?: 'MATCH_ALL' | 'MATCH_ANY';
  isVikingAnalyticsEnabled?: boolean;
};
export type EntityResponseDtoUserRequestDtoObject = {
  validation?: Validation;
  result?: UserRequestDtoObject;
};
export type EntityResponseDtoUserRequestDtoObjectRead = {
  validation?: Validation;
  result?: UserRequestDtoObjectRead;
};
export type RecordFetchResultDtoSimpleRecordDto = {
  apiVersion?: string;
  name?: string;
  page?: number;
  hits?: number;
  total?: number;
  results?: SimpleRecordDto[];
};
export type EntityResponseDtoRecordFetchResultDtoSimpleRecordDto = {
  validation?: Validation;
  result?: RecordFetchResultDtoSimpleRecordDto;
};
export type AddonDto = {
  id?: number;
  caption?: string;
  target?: string;
  jsonObject?: string;
  url?: string;
  icon?: string;
  displayType?: string;
  allModules?: boolean;
};
export type AddonResultDto = {
  success?: boolean;
  errorMessage?: string;
  validationStatus?: Validation;
  result?: AddonDto;
};
export type EntityResponseDtoAddonResultDto = {
  validation?: Validation;
  result?: AddonResultDto;
};
export type AddonMappingDto = {
  id?: number;
  caption?: string;
  displayType?: string;
  allModules?: boolean;
  allForms?: boolean;
  moduleId?: number;
  formId?: number;
};
export type EntityResponseDtoListAddonMappingDto = {
  validation?: Validation;
  result?: AddonMappingDto[];
};
export const {
  useGetThemeQuery,
  useLazyGetThemeQuery,
  useCreateSimpleRecordsMutation,
  useGetSimpleRecordQuery,
  useLazyGetSimpleRecordQuery,
  usePatchSimpleMutation,
  useGetBowtieConfigurationQuery,
  useLazyGetBowtieConfigurationQuery,
  useSetBowtieConfigurationMutation,
  useGetSimpleOptionsForFieldQuery,
  useLazyGetSimpleOptionsForFieldQuery,
  useCreateAndStartBowtieRecordsCreationJobMutation,
  useGetSimpleUsersQuery,
  useLazyGetSimpleUsersQuery,
  useGetSimpleCurrentUserQuery,
  useLazyGetSimpleCurrentUserQuery,
  useGetEnvironmentQuery,
  useLazyGetEnvironmentQuery,
  useGetSimpleRecords1Query,
  useLazyGetSimpleRecords1Query,
  useGetBackgroundJobDetailsQuery,
  useLazyGetBackgroundJobDetailsQuery,
  useGetAddon1Query,
  useLazyGetAddon1Query,
  useGetAddonMappingsQuery,
  useLazyGetAddonMappingsQuery,
} = injectedRtkApi;
