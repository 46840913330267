import { createSlice } from '@reduxjs/toolkit';
import { DIAGRAM_MODE } from '../../services/bowtie-data-types';
import { RootState } from '../store';

const defaultDiagramMode = localStorage.getItem('diagramMode');

interface FilterState {
  diagramMode: DIAGRAM_MODE;
  criticalFilterValues: {
    truthy: string;
    falsy: string;
  };
  effectiveFilterValues: {
    truthy: string;
    falsy: string;
  };
}

const initialState: FilterState = {
  diagramMode: (defaultDiagramMode as DIAGRAM_MODE) ?? DIAGRAM_MODE.BOWTIE,
  criticalFilterValues: {
    truthy: 'Critical',
    falsy: 'Non Critical',
  },
  effectiveFilterValues: {
    truthy: 'Effective',
    falsy: 'Not Effective',
  },
};

const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    toggleDiagram(state, action) {
      state.diagramMode = action.payload;
    },
  },
});

export const { toggleDiagram } = filterSlice.actions;

export const selectDiagramMode = (state: RootState) => state.filter.diagramMode;
export const selectCriticalFilterValues = (state: RootState) => state.filter.criticalFilterValues;
export const selectNonEffectiveFilterValues = (state: RootState) => state.filter.effectiveFilterValues;

export default filterSlice.reducer;
