import CausesContainerNode from '../components/container/causes-container-node.component';
import ConsequencesContainerNode from '../components/container/consequences-container-node.component';
import MitigatingControlsContainerNode from '../components/container/mitigating-controls-container-node.component';
import PreventativeControlsContainerNode from '../components/container/preventative-controls-container-node.component';
import CauseNode from '../components/node/cause-node.component';
import ConsequenceNode from '../components/node/consequence-node.component';
import HazardNode from '../components/node/hazard-node.component';
import MitigatingControlNode from '../components/node/mitigating-control-node.component';
import MueNode from '../components/node/mue-node.component';
import PreventativeControlNode from '../components/node/preventative-control-node.component';

const containerNodeTypes = {
  'causes-container': CausesContainerNode,
  'consequences-container': ConsequencesContainerNode,
  'mitigating-controls-container': MitigatingControlsContainerNode,
  'preventative-controls-container': PreventativeControlsContainerNode,
};

const controlNodeTypes = {
  'mitigating-control-node': MitigatingControlNode,
  'preventative-control-node': PreventativeControlNode,
};

const leafNodeTypes = {
  'cause-node': CauseNode,
  'consequence-node': ConsequenceNode,
  ...controlNodeTypes,
};

const mainNodeTypes = {
  'hazard-node': HazardNode,
  'mue-node': MueNode,
};

const nodeTypes = {
  ...leafNodeTypes,
  ...mainNodeTypes,
};

// node types that can be updated
export type MutableNodeTypes = keyof typeof nodeTypes;

// node types that can be added (created) or removed
export type LeafNodeTypes = keyof typeof leafNodeTypes | 'hazard-node';

// control node types
export type ControlNodeTypes = keyof typeof controlNodeTypes;

/**
 * A collection of diagram node types that combines container node types and other node types.
 *
 * @type {Object}
 * @property {Object} containerNodeTypes - The types of container nodes.
 * @property {Object} nodeTypes - The types of other nodes.
 */
export const diagramNodeTypes = {
  ...containerNodeTypes,
  ...nodeTypes,
};

/**
 * Enum representing the criticality of a control.
 *
 * @enum {number}
 * @readonly
 * @typedef {CriticalControlEnum}
 * @property {number} CRITICAL - Represents a critical control.
 * @property {number} NON_CRITICAL - Represents a non-critical control.
 */
export enum CriticalControlEnum {
  CRITICAL,
  NON_CRITICAL,
}

/**
 * Enum representing the effectiveness of a control.
 *
 * @enum {number}
 * @readonly
 * @typedef {EffectiveControlEnum}
 * @property {number} EFFECTIVE - Indicates that the control is effective.
 * @property {number} NON_EFFECTIVE - Indicates that the control is not effective.
 */
export enum EffectiveControlEnum {
  EFFECTIVE,
  NON_EFFECTIVE,
}

/**
 * Determines if a given node is a container node.
 *
 * @param nodeType - The node type to check.
 * @returns `true` if the node is a container node, otherwise `false`.
 */
export const isContainerNode = (nodeType: string): boolean => {
  return nodeType in containerNodeTypes;
};

/**
 * Type guard to check if the provided type is a leaf node type.
 *
 * @param type - The type string to check
 * @returns True if the type is a leaf node type, false otherwise
 *
 * @example
 * ```ts
 * if (isLeafNodeType(node.type)) {
 *   // node is a leaf node
 * }
 * ```
 */
export const isLeafNodeType = (type: string): type is LeafNodeTypes => {
  return type in leafNodeTypes;
};

/**
 * Determines if a node type is a control node type.
 *
 * @param type - The type of the node to check
 * @returns True if the node type is either a 'preventative-control-node' or 'mitigating-control-node'
 *
 * @example
 * ```typescript
 * isControlNode('preventative-control-node') // returns true
 * isControlNode('regular-node') // returns false
 * ```
 */
export const isControlNode = (type: string): type is ControlNodeTypes => {
  return type in controlNodeTypes;
};
