import { IconButton, OdinIcon, OdinIconSize, OdinIconType } from '@myosh/odin-components';
import React, { useEffect, useRef, useState } from 'react';
import { cn } from '../../helpers/util';
import { useAppDispatch } from '../../redux/hooks';
import { JobStatus, removeJob, SubmittedJobDetails } from '../../redux/slices/running-jobs';

interface RunningJobsPanelProps {
  submittedJobs: SubmittedJobDetails[];
}

const RunningJobsPanel: React.FC<RunningJobsPanelProps> = ({ submittedJobs }) => {
  const panelButtonRef = useRef<HTMLButtonElement>(null);
  const dispatch = useAppDispatch();

  const [isPanelVisible, setIsPanelVisible] = useState(false);
  const [hasViewedJobs, setHasViewedJobs] = useState(false);

  const onPanelButtonClick = () => setIsPanelVisible(!isPanelVisible);
  const onCloseButtonClick = () => setIsPanelVisible(false);

  const getPanelButtonStatusIcon = () => {
    const statusIconClasses = 'bt-absolute bt-top-[-10px] bt-right-[-10px]';
    const latestJobStatus = submittedJobs[0].status;

    if (submittedJobs.some((job: SubmittedJobDetails) => job.status === JobStatus.InProgress)) {
      return <OdinIcon icon="Loader" size={OdinIconSize.ExtraSmall} className={statusIconClasses} spin />;
    } else if (hasViewedJobs) {
      return null;
    } else if (latestJobStatus === JobStatus.Failed) {
      return (
        <OdinIcon icon="ErrorWarning" size={OdinIconSize.ExtraSmall} className={`${statusIconClasses} bt-text-error`} />
      );
    } else if (latestJobStatus === JobStatus.Success) {
      return (
        <OdinIcon
          icon="CheckboxCircle"
          size={OdinIconSize.ExtraSmall}
          className={`${statusIconClasses} bt-text-success`}
        />
      );
    } else {
      return null;
    }
  };

  const onJobClick = (job: SubmittedJobDetails) => {
    if (job && job.url && job.name && job.status === JobStatus.Success) {
      window.open(job.url);
    }
  };

  const onJobRemove = (id: number) => {
    if (id) {
      dispatch(removeJob(id));
    }
  };

  const getStatusIcon = (status?: JobStatus) => {
    if (status) {
      if (status === JobStatus.InProgress) {
        return <OdinIcon icon="Loader" size={OdinIconSize.ExtraSmall} spin />;
      } else if (status === JobStatus.Success) {
        return <OdinIcon icon="CheckboxCircle" size={OdinIconSize.ExtraSmall} className="bt-text-success" />;
      } else if (status === JobStatus.Failed) {
        return <OdinIcon icon="ErrorWarning" size={OdinIconSize.ExtraSmall} className="bt-text-error" />;
      }
    }
  };

  useEffect(() => {
    if (isPanelVisible) {
      if (submittedJobs.every((job: SubmittedJobDetails) => job.status !== JobStatus.InProgress)) {
        setHasViewedJobs(true);
      } else {
        setHasViewedJobs(false);
      }
    }

    if (submittedJobs.length === 0) {
      setIsPanelVisible(false);
    }
  }, [isPanelVisible, submittedJobs]);

  return (
    <div className="bt-flex bt-items-center">
      {submittedJobs && submittedJobs.length > 0 && (
        <div className="bt-relative bt-mt-[0.4rem] bt-space-y-2 bt-rounded-b-lg bt-bg-mono-1 bt-p-4">
          <button ref={panelButtonRef} onClick={onPanelButtonClick} className="bt-relative">
            <OdinIcon icon="FolderDownload" type={OdinIconType.Line} />
            {getPanelButtonStatusIcon()}
          </button>
          {isPanelVisible && (
            <div className="bt-z-4001 bt-absolute bt-top-10 bt-min-w-[14rem] bt-max-w-[25rem] bt-rounded-lg bt-bg-gray-5 bt-shadow-lg">
              <div className="bt-flex bt-items-center bt-justify-between bt-p-4">
                <span className="bt-whitespace-nowrap">Job Status</span>
                <IconButton onClick={onCloseButtonClick}>
                  <OdinIcon icon="Close" />
                </IconButton>
              </div>
              <div className="bt-space-y-2 bt-rounded-b-lg bt-bg-mono-1 bt-p-4">
                {submittedJobs.map((job: SubmittedJobDetails) => {
                  const itemStyles = cn('bt-flex bt-gap-2 bt-items-center bt-min-w-0 bt-w-full', {
                    'hover:bt-cursor-pointer': job.status === JobStatus.Success,
                  });

                  const removeIconStyles = cn(
                    'bt-w-4 bt-h-4 bt-rounded-full bt-hover:bg-primary-5 hover:bt-cursor-pointer',
                    {
                      'bt-pointer-events-none': job.status === JobStatus.InProgress,
                    }
                  );

                  return (
                    <div key={job.id} className="bt-flex bt-items-center bt-p-1 [&:has(div:hover)]:bt-bg-primary-5">
                      <div className={itemStyles} onClick={() => onJobClick(job)}>
                        <OdinIcon icon="FileText" type={OdinIconType.Line} />
                        <span className="bt-flex-1 bt-truncate" title={job.name}>
                          {job.name}
                        </span>
                        <span>{getStatusIcon(job.status)}</span>
                      </div>
                      <span className={removeIconStyles} onClick={() => onJobRemove(job.id)}>
                        {job.status !== JobStatus.InProgress && (
                          <OdinIcon icon="Close" size={OdinIconSize.ExtraSmall} />
                        )}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default RunningJobsPanel;
