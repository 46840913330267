import { Button } from '@myosh/odin-components';
import { useEffect, useRef, useState } from 'react';
import { Id, toast } from 'react-toastify';

import { useLocation } from 'react-router-dom';
import {
  useCreateAndStartBowtieRecordsCreationJobMutation,
  useGetBackgroundJobDetailsQuery,
} from '../../api/enhanced/enhanced-v4-api';
import { BowtieDto } from '../../api/generated/v4-api';
import recordSelectors from '../../redux/diagram/diagramSelectors';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { JobStatus, setJobStatus, submitJob } from '../../redux/slices/running-jobs';
import { BowtieStateCauseData, BowtieStateConsequenceData, BowtieStateData } from '../../services/bowtie-data-types';
import { setDiagramDisabled } from '../../redux/slices/diagram';

interface CreateRecordsButtonProps {
  // if provided will be used to create the records
  bowtieDto?: BowtieDto;
  // optional props are temporary and are used with 'flow' diagrams
  mueLabel?: string;
  mueRecordId?: number;
}

// A button that creates the backed records for the AI generated diagram
const CreateRecordsButton = ({ bowtieDto: flowBowtieDto, mueLabel, mueRecordId }: CreateRecordsButtonProps) => {
  const [jobId, setJobId] = useState<number>();
  const bowtieData: BowtieStateData = useAppSelector(recordSelectors.selectBowtieData);
  const toastIdRef = useRef<Id>();

  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const isFlowDiagram = pathname.includes('flow');

  const [postCreateRecordsJob] = useCreateAndStartBowtieRecordsCreationJobMutation();

  const { data: { status, jobDetails } = {}, isError } = useGetBackgroundJobDetailsQuery(
    { id: jobId! },
    {
      skip: !jobId,
      pollingInterval: jobId ? 5000 : 0, // 5 seconds polling interval
    }
  );

  useEffect(() => {
    if ('FINISHED' === status && jobDetails && toastIdRef.current) {
      const parsedJobDetails = JSON.parse(jobDetails);

      if (parsedJobDetails?.recordId) {
        const params = new URLSearchParams({ records: String(parsedJobDetails.recordId) });

        toast.update(toastIdRef.current, {
          render: 'Diagram created successfully',
          type: 'success',
          isLoading: false,
          autoClose: 2000,
        });

        dispatch(
          setJobStatus({
            id: jobId!,
            status: JobStatus.Success,
            url: `${window.location.origin}${pathname}?${params}`,
            completedDate: new Date().toISOString(),
          })
        );
      }
      if (isFlowDiagram) {
        dispatch(setDiagramDisabled(false));
      }
      setJobId(undefined);

      const params = new URLSearchParams({ records: String(parsedJobDetails.recordId) });
      setTimeout(() => window.location.replace(`${window.location.origin}${pathname}?${params}`), 2500);
    } else if (('STAGED' === status || isError) && toastIdRef.current) {
      toast.update(toastIdRef.current, {
        render: 'Unable to create diagram',
        type: 'error',
        isLoading: false,
        autoClose: 2000,
      });
      dispatch(
        setJobStatus({
          id: jobId!,
          status: JobStatus.Failed,
          completedDate: new Date().toISOString(),
        })
      );
      if (isFlowDiagram) {
        dispatch(setDiagramDisabled(false));
      }
      setJobId(undefined);
    }
  }, [status, isError]);

  const handleClick = async () => {
    if (isFlowDiagram && !mueLabel && !mueRecordId) {
      toast.error('Please specify the Material Unwanted Event', { autoClose: 2000 });
      return;
    }

    toastIdRef.current = toast.loading('Creating backend records...');

    // post records creation job
    const bowtieDto = flowBowtieDto ? { bowtieDto: flowBowtieDto } : createBowtieDto(bowtieData);

    const { id } = (await postCreateRecordsJob(bowtieDto).unwrap()) ?? {};

    setJobId(id);
    if (id) {
      dispatch(submitJob({ name: bowtieDto.bowtieDto.riskScenario, id: id! }));
      if (isFlowDiagram) {
        dispatch(setDiagramDisabled(true));
      }
    }
  };

  return (
    <Button variant="alternative" onClick={handleClick} type="primary" disabled={Boolean(jobId)}>
      {'Create Backend Records'}
    </Button>
  );
};

export default CreateRecordsButton;

// util
const createBowtieDto = (bowtieData: BowtieStateData) => {
  return {
    bowtieDto: {
      hazard: bowtieData.hazard,
      riskScenario: bowtieData.scenario,
      causes: bowtieData.causes?.map((cause: BowtieStateCauseData) => ({
        cause: cause.value,
        controls: cause.preventativeControls?.map((control) => ({ name: control.value })),
      })),
      consequences: bowtieData.consequences?.map((consequence: BowtieStateConsequenceData) => ({
        consequence: consequence.value,
        controls: consequence.mitigatingControls?.map((control) => ({ name: control.value })),
      })),
    },
  };
};
