import { Button, OdinIcon } from '@myosh/odin-components';
import { forwardRef, Ref, RefObject, useCallback, useImperativeHandle, useRef } from 'react';
import Dialog, { DialogRef } from './dialog';

interface AddItemDialogProps {
  onSubmit: () => void;
  errorTextRef: RefObject<HTMLParagraphElement>;
  inputRef: RefObject<HTMLInputElement>;
  disabled?: boolean;
}

const AddItemDialog = ({ onSubmit, errorTextRef, inputRef, disabled }: AddItemDialogProps, ref: Ref<DialogRef>) => {
  const dialogRef = useRef<DialogRef>(null);

  const close = useCallback(() => {
    if (disabled) return;
    dialogRef.current?.close();
  }, []);

  useImperativeHandle(ref, () => ({
    close,
    open: () => dialogRef.current?.open(),
  }));

  return (
    <Dialog
      onCancel={close}
      className="bt-m-auto bt-w-[256px] bt-rounded-md bt-p-4 bt-text-gray-1 bt-outline-none"
      ref={dialogRef}
    >
      <div className="bt-flex bt-flex-col bt-gap-4">
        <div className="bt-w-full">
          <div className="bt-mb-2 bt-flex bt-flex-row-reverse">
            <div title="Close" className="bt-cursor-pointer bt-text-primary-2" onClick={close}>
              <OdinIcon icon="Close" />
            </div>
          </div>
          <label htmlFor="term-input" className="bt-flex bt-self-start bt-text-sm bt-font-bold">
            Add new item
          </label>
          <input
            disabled={disabled}
            placeholder="enter new item"
            ref={inputRef}
            name="add-new-item-input"
            className="bt-flex bt-w-full bt-flex-1 bt-appearance-none bt-border bt-border-gray-4 bt-px-2 bt-py-1 bt-text-sm focus:bt-outline-none"
          />
          <p className="bt-hidden bt-text-xs bt-text-error" ref={errorTextRef} />
        </div>
        <Button disabled={disabled} onClick={onSubmit}>
          Submit
        </Button>
      </div>
    </Dialog>
  );
};

export default forwardRef(AddItemDialog);
